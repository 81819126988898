import React, { MouseEventHandler, useState } from 'react';
import EditTextField, { EditTextFieldProps } from './EditField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextFieldProps } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';

interface DatePickerEditFieldProps {
    label: string;
    value?: Date | null;
    onChange: (newValue: Date | null) => void;
    type?: 'date' | 'time' | 'datetime';
    // Inherit other TextField props
}

const DatePickerEditField: React.FC<DatePickerEditFieldProps & EditTextFieldProps> = ({
    label,
    value,
    onChange,
    type = 'date',
    ...otherProps
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(value || null);

    const handleOpen = (event: any) => {
        event.stopPropagation(); // Prevent bubbling
        setIsOpen(true);
    };

    const handleClose = () => setIsOpen(false);

    const handleDateChange = (newValue: Date | null) => {
        setSelectedDate(newValue);
        onChange(newValue); // Update parent state
    };

    const formatDate = (date: Date | null) => {
        if (!date) return ''; // Handle null values
        date = new Date(date);
        
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    };

    const formatTime = (date: Date | null) => {
        if (!date) return ''; // Handle null values
        date = new Date(date);

        return date.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
        });
    };

    const content = type === 'date' ? (
        <MobileDatePicker
            open={isOpen}
            onClose={handleClose}
            value={selectedDate}
            onChange={handleDateChange}
            views={['year', 'month', 'day']} // Display date picker
            renderInput={(params) => <></>}
            DialogProps={{
                style: { zIndex: 99999999999 }, // Example zIndex
            }}
        />
    ) : type === 'time' ? (
        <MobileTimePicker
            open={isOpen}
            onClose={handleClose}
            value={selectedDate}
            onChange={handleDateChange}
            views={['hours', 'minutes']} // Display time picker
            renderInput={(params) => <></>}
            DialogProps={{
                style: { zIndex: 99999999999 }, // Example zIndex
            }}
        />
    ) : (
        // Handle time and datetime logic if needed 
        <></>
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <EditTextField
                {...otherProps as TextFieldProps}
                label={label}
                value={!!selectedDate ? 
                    type === 'date' ? formatDate(selectedDate) :
                    type === 'time' ? formatTime(selectedDate) : 
                    type === 'datetime' ? `${formatDate(selectedDate)} / ${formatTime(selectedDate)}` : '' : ''
                }
                disabled={type === 'datetime'} // Disable for datetime for now
                onClick={handleOpen}
            />
            {content}
        </LocalizationProvider>
    );
};

export default DatePickerEditField;
import { useEffect, useState, useMemo } from 'react'
import { DivContainer, FlexEvenly } from '../../assets/styles/styledComponents';
import List from '../../components/ui/List';
import { Patient } from './Patient';
import { PATIENT_COLUMNS } from '../../data/GridColumnDefinitions';
import useApi_DEPRACATED from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import SearchInput from '../../components/common/SearchInput/SearchInput';
import { BASE_URL, appModules } from '../../data/constants';
import DataGrid from '../../components/ui/DataGrid';
import Loader from '../../components/ui/Loader';
import AddFab from '../../components/ui/AddFab';
import useAuth from '../../features/authentication/hooks/useAuth';
import ContactEdit, { ContactFormData } from '../Caregivers/ContactEdit';
import { Grid, IconButton, Tooltip } from '@mui/material';
import FilterSelect from '../../components/FilterSelect';
import { Delete } from '@mui/icons-material';
import { usePatientActions } from './actions';
import ConfirmationDialog from '../../components/ui/Modals/components/ConfirmationDailog';

type patientEntryType = {
  address: string,
  admissionId: string,
  coordinatorName: string | null,
  dateOfBirth: string,
  gender: string,
  hhaCoordinatorId: number | null,
  hhaOfficeId: number | null,
  hhaPatientId: number,
  languages: string | null,
  name: string,
  officeName: string | null,
  patientId: number,
  phone: string,
  source: string
}

type PatientsProps = {

}

const Patients: React.FC<PatientsProps> = () => {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState<patientEntryType[]>([]);
  const { getList, data, isLoading } = useApi_DEPRACATED(appModules.patient, BASE_URL);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [rowCountState, setRowCountState] = useState<number>(0);
  const [open_contactEdit, set_open_contactEdit] = useState<boolean>(false);
  const [patientType, setPatientType] = useState<string>('patient');
  const [patientDeleteConfirmation_id, set_patientDeleteConfirmation_id] = useState<number>(0);

  const { getUser } = useAuth();
  const user = getUser();
  const { deletePatient } = usePatientActions();

  // useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     data?.list?.totalRows !== undefined ?  data?.list?.totalRows : prevRowCountState,
  //   );
  // }, [data?.list?.totalRows, setRowCountState]);

  const actionColumns = [
    {
      field: 'actions', headerName: '', align: 'center', flex: 0.1, renderCell: (params: any) => {

        if (params.row.source?.toLowerCase() !== 'referral')
          return <></>;

        const patientId: number = params.row.patientId;

        return (
          <FlexEvenly>
            <Tooltip title='Delete Patient'>
              <IconButton onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                set_patientDeleteConfirmation_id(patientId);
              }}>
                <Delete fontSize="small" color="primary" />
              </IconButton>
            </Tooltip>
          </FlexEvenly >
        )
      }
    }
  ]

  // First checks if any are === then if it includes
  useEffect(() => {
    const exactMatchAttributes: string[] = [
      'hhaPatientId', 'admissionId', 'patientId',
    ];
    const partialMatchAttributes: string[] = [
      'name',
    ];

    const results = data?.list?.filter((item: any) => {
      return exactMatchAttributes.some((key) => {
        const value = item[key as keyof patientEntryType];
        const stringValue = String(value).toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return value !== null && stringValue === lowerCaseSearchTerm;
      }) || partialMatchAttributes.some((key) => {
        const value = item[key as keyof patientEntryType];
        const stringValue = String(value).toLowerCase();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        return value !== null && stringValue.includes(lowerCaseSearchTerm);
      });
    });

    const list = results?.map((item: patientEntryType) => getWithSuffix(item));
    setPatientList(list);
  }, [searchTerm, data]);

  const getQuery = () => {
    return `?patientType=${patientType}`
  }
  const queryOptions = useMemo(
    () => ({
      page,
      pageSize,
    }),
    [page, pageSize],
  );

  useEffect(() => {
    reload()
  }, [page, patientType])

  const reload = () => {
    // getList(`/paging?pageIndex=${page}&pageSize=${pageSize}`) 
    getList(getQuery())
  }

  const options = {
    rowHeight: 36,
    headerHeight: 40,
    hideToolbarIconText: true,
    hideExport: true,
    pagination: true,
    // rowsPerPageOptions: [pageSize],
    // page: page,
    // pageSize: pageSize,
    // paginationMode:"server",
    // onPageChange:(newPage: number) => {
    //   setPage(newPage + 1)
    // },
    // onPageSizeChange:(newPageSize: number) => {
    //   setPageSize(newPageSize)
    // }

  }

  const handleRowClick = (param: any) => {
    navigate(`/patient/${param.row.patientId}/details`);
  }

  const handleSearch = (query: string) => {
    setSearchTerm(query)
  }

  const columns = PATIENT_COLUMNS.concat(actionColumns);

  return (
    <div style={{ padding: "10px 10px 10px 10px" }}>
      <Loader isLoading={isLoading} />
      <Grid container spacing={1}>
        <Grid item xs={6.5} sm={7.5} md={8.75} lg={9.4} xl={9.6}>
          <SearchInput getSearchQuery={handleSearch} />
        </Grid>
        <Grid item xs={2} style={{ marginLeft: '5px', marginRight: '5px' }}>
          <FilterSelect
            label='Patient Type'
            value={patientType}
            handleChange={(e: any) => setPatientType(e.target.value)}
            options={['Patient', 'Referral', 'Any']}
            sx={{ minWidth: '180px', fontSize: '14px' }}
          />
        </Grid>
        {
          !user?.isAdminOrSuperAdmin ? null :
            <Grid item xs={2} md={0.25}>
              <AddFab
                title={'Create Patient'}
                clickAction={() => { set_open_contactEdit(true) }}
              />
            </Grid>
        }
      </Grid>
      <DataGrid
        title="Patient List"
        onRowClick={handleRowClick}
        data={{
          columns: columns,
          rows: patientList
        }}
        options={options}
      // rowCount={rowCountState}
      />

      <ContactEdit
        open={open_contactEdit}
        onSave={() => { set_open_contactEdit(false); reload(); }}
        onClose={() => { set_open_contactEdit(false) }}
        formData={{} as ContactFormData}
        module={'Patient'}
      />
      <ConfirmationDialog
        open={!!patientDeleteConfirmation_id}
        onClose={async (answer) => {
          if (!!answer) {
            deletePatient(patientDeleteConfirmation_id).then((res: any) => {
              if (res === true) {
                console.log(res);
                setPatientList((prev) => prev.filter((patient) => patient.patientId !== patientDeleteConfirmation_id));
                set_patientDeleteConfirmation_id(0)    
              }
            });
          } else {
            set_patientDeleteConfirmation_id(0)
          }
        }}
        action="Delete"
        item="Patient"
      />
    </div>
  )
}

export default Patients

const getWithSuffix = (item: patientEntryType): patientEntryType => {
  const suffix = item?.source == 'Referral' ? '[REFERRAL]' : '';
  const updated: patientEntryType = { ...item, name: `${item.name}  ${suffix}` }
  return updated;
}
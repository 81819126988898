import { Box, Checkbox, Chip, FormControlLabel, FormHelperText, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip } from "@mui/material"
import DataGrid from "../../components/ui/DataGrid"
import { useEffect, useState } from "react"
import useFetcher from "../../hooks/useFetcher";
import { COORDINATOR_COLS } from "../../data/GridColumnDefinitions";
import { AddIcCall, Edit, Tag, } from "@mui/icons-material";
import { BoldTitle, Text } from "../../assets/styles/styledComponents";
import useToast from "../../hooks/useToast";
import SearchInput from "../../components/common/SearchInput/SearchInput";
import NewModal from "../../components/ui/NewModal";
import AddFab from "../../components/ui/AddFab";
import Loader from "../../components/ui/Loader";

const CoordinatorList = () => {
    const [users, setUsers] = useState<any>([]);
    const [supervisors, setSupervisors] = useState<any>([]);
    const [coordinators, setCoordinators] = useState([]);
    const [selectedCoordinator, setSelectedCoordinator] = useState<any>();
    const [openActionModal, setOpenActionModal] = useState(false);
    const [openAttachModal, setOpenAttachModal] = useState(false);
    const fetcher = useFetcher();
    const { showError, showSuccess } = useToast();
    const [addUser, setAddUser] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState<any>([])
    const [selectedPhone, setSelectedPhone] = useState<any>();
    const [action, setAction] = useState('');
    const [eMessage, setEMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filteredRows, setFilteredRows] = useState<any[]>([])

    useEffect(() => {
        getCoordinators()
        getUsers()
        getSupervisors()
    }, [])

    const getCoordinators = () => {
        fetcher.get('coordinator/all').then((res: any) => {
            setCoordinators(res.data.data)
        })
    }

    const getUsers = () => {
        if (!users?.length) {
            fetcher.get('user/all').then((res: any) => {
                setUsers(res.data)
            })
        }
    }

    const getSupervisors = () => {
        if (!users?.length) {
            fetcher.get('filter/supervisors').then((res: any) => {
                setSupervisors(res.data.data)
            })
        }
    }

    const getPhoneNumbers = () => {
        fetcher.get('coordinator/phones').then((res: any) => {
            if (res.data.message != 'Success')
                setEMessage(res.data.data)

            setPhoneNumbers(res?.data?.data?.result?.result)
        })
    }

    const handleClickEdit = (row: any) => {
        setAction('Edit')
        setSelectedCoordinator(row)
        setOpenActionModal(true)
    }

    const handleClickAdd = () => {
        setAction('Create')
        setSelectedCoordinator({
            id: 0,
            hhaCoordinatorId: 0,
            name: '',
            email: '',
            userId: null,
            supervisorId: null,
            officeId: null,
        })
        setOpenActionModal(true)
    }

    const handleCloseActionModal = () => {
        setAction('')
        setOpenActionModal(false)
        setSelectedCoordinator(null)
    }

    const handleClickAttach = (row: any) => {
        getPhoneNumbers()
        setSelectedCoordinator(row)
        setOpenAttachModal(true)
    }

    const handleCloseAttachModal = () => {
        setOpenAttachModal(false)
        setSelectedCoordinator(null)
    }

    const handleChangeField = (field: any, value: any) => {
        setSelectedCoordinator({
            ...selectedCoordinator,
            [field]: value
        })
    }

    const handleNumericChangeField = (field: any, value: any) => {
        if (/^[0-9]*$/.test(value)) {
            handleChangeField(field, value)
        } else {
            return;
        }
    };


    const getStatusColor = (isActive: boolean) => {
        if (!!isActive)
            return 'green'
        if (!isActive)
            return 'red'
    }
    const getCoordinatorCols = () => {
        return [
            ...COORDINATOR_COLS,
            {
                field: 'extension', headerName: 'Extenstion', renderCell: ((params: any) => {
                    if (params.row.extension == 0)
                        return (
                            <Text>-</Text>
                        )
                })
            },
            {
                field: 'isActive', headerName: '', renderCell: ((params: any) => {
                    return (
                        <Chip
                            label={(!!params.row.isActive ? 'Active' : 'Inactive')}
                            sx={{ width: '90px', borderRadius: '3px', border: `0px solid ${getStatusColor(params.row.isActive)}`, color: getStatusColor(params.row.isActive), background: 'white', height: '30px' }}
                        />
                    )
                })
            },
            {
                field: "actions", renderCell: (params: any) => (
                    <>
                        <Tooltip title='Edit Coordinator'>
                            <IconButton onClick={() => handleClickEdit(params.row)}>
                                <Edit color="primary" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Connect Phone Number'>
                            <IconButton onClick={() => handleClickAttach(params.row)}>
                                <AddIcCall color="primary" fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </>
                )
            },
        ]
    }

    const saveChanges = () => {
        const coordinator = {
            id: selectedCoordinator.id,
            hhaCoordinatorId: selectedCoordinator.hhaCoordinatorId,
            name: selectedCoordinator.name,
            email: selectedCoordinator.email,
            userId: selectedCoordinator.userId,
            supervisorId: selectedCoordinator.supervisorId,
            officeId: selectedCoordinator.officeId,
            extension: selectedCoordinator.extension || 0,
            smsNumber: selectedPhone
        }

        fetcher.put(`coordinator/update?addUser=${addUser}`, coordinator).then(() => {
            handleCloseActionModal()
            getCoordinators()
            showSuccess('User updated succesfully')
        }).catch((err: any) => [
            showError(err.response.data.message)
        ])
    }

    const assignNumber = () => {
        fetcher.get(`coordinator/attach_phone?coordinatorId=${selectedCoordinator.id}&phoneNumber=${selectedPhone}`).then(() => {
            handleCloseAttachModal()
            getCoordinators()
            showSuccess("Number Assigned Successfully")
        }).catch((err: any) => {
            showError(err.data)
        })
    }

    const filteredUsers = users?.filter((user: any) => {
        const isCoordinator = coordinators.some((c: any) => c.userId === user?.id);
        return !isCoordinator || user?.id === selectedCoordinator?.userId;
    });

    const handleAddUser = () => {
        setAddUser(!addUser)
    }

    const handleSearch = (query: string) => {
        setSearchTerm(query)
    }

    const [activeState, setState] = useState<'active' | 'inactive' | 'all'>('active');
    const states = ['active', 'inactive', 'all'] as const;

    useEffect(() => {
        const results = coordinators?.filter((item: any) => {
            if (activeState !== 'all') {
                if (!item.isActive && activeState !== 'inactive')
                    return false;
                if (!!item.isActive && activeState !== 'active')
                    return false;
            }

            const values = Object.values(item);
            return values.some((value) => String(value).toLowerCase().includes(searchTerm.toLowerCase()));
        });

        setFilteredRows(results)
    }, [searchTerm, coordinators, activeState])

    const handleClick = () => {
        const nextIndex = (states.indexOf(activeState) + 1) % states.length;
        setState(states[nextIndex]);
    };

    return (
        <Box padding='10px'>
            <Loader isLoading={fetcher.isLoading} />
            <SearchInput getSearchQuery={handleSearch} />
            <Chip
                label={activeState.charAt(0).toUpperCase() + activeState.slice(1)}
                onClick={handleClick}
                sx={{
                    borderRadius: '16px',
                    cursor: 'pointer',
                    backgroundColor: activeState === 'active' ? '#4caf50' : activeState === 'inactive' ? '#f44336' : '#7846ff',
                    color: '#fff',
                    '&:hover': {
                        opacity: 0.8,
                    },
                    float: 'right'
                }}
            />
            <DataGrid
                title="Coordinators"
                data={{
                    columns: getCoordinatorCols(),
                    rows: filteredRows || []
                }}
                options={{
                    headerBGColor: 'rgb(232,224,255)',
                    hideExport: true,
                    hideFiltering: true,
                    hideColumns: true,
                }}
            />
            <AddFab title={'Add Coordinator'} clickAction={() => handleClickAdd()} />

            {/* Action Modal */}
            <NewModal
                open={openActionModal}
                title={`${action} Coordinator`}
                height="570px"
                onClose={() => handleCloseActionModal()}
                onSave={() => saveChanges()}
            >

                <Box padding={'10px'} display={'block'}>
                    <BoldTitle>Name</BoldTitle>
                    <TextField
                        placeholder="John Doe"
                        margin="dense"
                        fullWidth
                        size="small"
                        value={selectedCoordinator?.name}
                        onChange={(e) => handleChangeField('name', e.target.value)}
                    />
                    <BoldTitle>Email</BoldTitle>
                    <TextField
                        placeholder="example@example.com"
                        margin="dense"
                        fullWidth
                        size="small"
                        value={selectedCoordinator?.email}
                        onChange={(e) => handleChangeField('email', e.target.value)}
                    />
                    <BoldTitle>User</BoldTitle>
                    <Select
                        size="small"
                        sx={{ margin: '5px 0' }}
                        fullWidth
                        value={selectedCoordinator?.userId}
                        onChange={(e) => handleChangeField('userId', e.target.value)}
                    >
                        {filteredUsers?.map((user: any) => (
                            <MenuItem key={user?.id} value={user?.id}>{user?.id + " - " + user?.email}</MenuItem>
                        ))}
                    </Select>
                    <FormControlLabel label={'Create New User for Coordinator'} control={<Checkbox size="small" checked={addUser} onClick={() => handleAddUser()} />}></FormControlLabel>
                    <BoldTitle>Supervisor</BoldTitle>
                    <Select
                        size="small"
                        sx={{ margin: '5px 0' }}
                        fullWidth
                        value={selectedCoordinator?.supervisorId}
                        onChange={(e) => handleChangeField('supervisorId', e.target.value)}
                    >
                        {supervisors?.map((supervisor: any) => (
                            <MenuItem key={supervisor.id} value={supervisor.id}>{supervisor.name}</MenuItem>
                        ))}
                    </Select>
                    <BoldTitle>Extension</BoldTitle>
                    <TextField
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Tag fontSize="small" />
                                </InputAdornment>
                            )
                        }}
                        placeholder="1001"
                        margin="dense"
                        fullWidth
                        size="small"
                        value={selectedCoordinator?.extension}
                        onChange={(e) => handleNumericChangeField('extension', e.target.value)}
                    />
                    <FormHelperText>Enter Numeric Values Only</FormHelperText>
                </Box>
            </NewModal>

            {/* Attach Modal */}
            <NewModal
                open={openAttachModal}
                title={'Assign Phone #'}
                height="225px"
                onClose={() => handleCloseAttachModal()}
                onSave={() => assignNumber()}
            >
                <Box>
                    <BoldTitle>Select #</BoldTitle>
                    <Select
                        size="small"
                        sx={{ marginTop: '5px' }}
                        fullWidth
                        value={selectedPhone || selectedCoordinator?.phoneNumber}
                        onChange={(e) => setSelectedPhone(e.target.value)}
                    >
                        {phoneNumbers?.map((phone: any) => (
                            <MenuItem key={phone.sms_Line} value={phone.sms_Line}>{phone.sms_Line}</MenuItem>
                        ))}
                    </Select>
                    {<FormHelperText sx={{ color: 'red' }}>{eMessage}</FormHelperText>}
                </Box>
            </NewModal>
        </Box>
    )
}

export default CoordinatorList
import useFetcher from "../../hooks/useFetcher";
import useToast from "../../hooks/useToast";

export const usePatientActions = () => {
  const { showSuccess, showError } = useToast();
  const patientDeleteFetcher = useFetcher();

  const deletePatient = async (id: number): Promise<boolean> => {
    try {
      await patientDeleteFetcher.delete(`/patient/delete/${id}`);
      showSuccess("Patient has been successfully deleted.");
      return true;
    } catch (error: any) {
      const response = error.response?.data;
      showError(response?.message ?? error.message);
      return false;
    }
  };

  return { deletePatient };
};

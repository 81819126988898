import { useState, useEffect, useCallback } from 'react';
import DataGrid from '../../../components/ui/DataGrid';
import { dateTime } from '../../../helper/datetime';
import { Autocomplete, Badge, Box, Button, FormControlLabel, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Radio, RadioGroup, TextField, TextareaAutosize, Tooltip, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckIcon from '@mui/icons-material/Check';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import ReplayIcon from '@mui/icons-material/Replay';
import useFetcher from '../../../hooks/useFetcher';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { Bold, BoldTitle, FlexAlignCenter, Title } from '../../../assets/styles/styledComponents';
import useToast from '../../../hooks/useToast';
import Loader from '../../../components/ui/Loader';
import NewModal from '../../../components/ui/NewModal';
import { CancelOutlined, ChatOutlined, CheckCircle, CheckCircleOutline, Delete, Edit, InfoOutlined, LiveHelpOutlined, Repeat, StickyNote2Outlined, TuneOutlined, ViewListOutlined, ViewModuleOutlined } from '@mui/icons-material';
import useAuth from '../../authentication/hooks/useAuth';
import dayjs, { Dayjs } from 'dayjs';
import AddFab from '../../../components/ui/AddFab';
import SendInvite from './Modals/InviteModal/SendInvite';
import InviteFilters from '../../../components/form/Filters/InviteFilters';
import { DataGridProProps, GridRowId } from '@mui/x-data-grid-pro';
import EditInvite from './Modals/EditInvite.tsx/EditInvite';
import { useLocation } from 'react-router-dom';
import InviteStatusEdit from './Modals/EditInvite.tsx/InviteStatusEdit';
import { dateSortComparator } from '../../../data/GridColumnDefinitions';
import useInviteUI from '../../invites/hooks/useInviteUI';
import { strings } from '../../../helper/strings';

type note = {
    Body: string,
    UserId: number,
    InviteId?: number,
    PatientId?: number | null,
    CaregiverId?: number | null,
}

const Invites = () => {
    const { get, post, isLoading } = useFetcher('')
    const fetcher = useFetcher();
    const invokeFetcher = useFetcher();
    const assignCarergiverFetcher = useFetcher();
    const [rows, setRows] = useState([]);
    const { showSuccess, showError } = useToast();
    const { getUser } = useAuth();
    const user = getUser();
    const [row, setRow] = useState<any>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [payCodes, setPayCodes] = useState<any[]>([])
    const [startTime, setStartTime] = useState<Dayjs | null>(null);
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const [openNotes, setOpenNotes] = useState(false)
    const [notes, setNotes] = useState<any[]>([]);
    const [addNote, setAddNote] = useState(false);
    const [deleteNote, setOpenDelete] = useState(false);
    const [selectedNote, setSelectedNote] = useState<any>(null);
    const [noteBody, setNoteBody] = useState('');
    const [noteType, setNoteType] = useState('');
    const [openCreate, setOpenCreate] = useState(false)
    const [openFilters, setOpenFilters] = useState(false);
    const [openEdit, setOpenEdit] = useState(false)
    const [query, setQuery] = useState<any>();
    const [groupedRows, setGroupedRows] = useState<any>([]);
    const [openResend, setOpenResend] = useState(false);
    const [selectedPayCodesByVisit, setSelectedPayCodesByVisit] = useState<any>({});
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<GridRowId[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openStatus, setOpenStatus] = useState(false);
    const [caseBased, setCaseBased] = useState(true);
    const [modalOpened, setModalOpened] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const [sentDates, setSentDates] = useState<[Dayjs | null, Dayjs | null]>([dayjs(new Date().setDate(new Date().getDate() - 7)), dayjs(new Date())]);
    const [visitDates, setVisitDates] = useState<[Dayjs | null, Dayjs | null]>([dayjs(new Date()), dayjs(new Date().setDate(new Date().getDate() + 7))]);
    const [selectedCoordinators, setSelectedCoordinators] = useState<any>([]);
    const [selectedStatus, setSelectedStatus] = useState<any>(['Pending', 'Accepted', 'Declined']);
    const sentInviteCode = queryParams.get('si');
    
    const handleOpenModal = (invite: any) => { setModalOpen(true); setRow(invite); }
    const handleCloseModal = () => {
        setRow([])
        setModalOpen(false);
    };

    const { getStatusColor, GroupInvitesByInviteCode, getGroupedRowStatus, StatusButton, getGroupedVisitStatus } = useInviteUI();

    const defaultCoordinatorsFetch = useFetcher("filter/default_coordinators");

    useEffect(() => {
        setSelectedCoordinators(defaultCoordinatorsFetch.data || []);
    }, [defaultCoordinatorsFetch.data,]);


    useEffect(() => {
        if (rows.length < 1 || modalOpened)
            return;

        const selected = rows.find((r: any) => r.inviteCode == sentInviteCode)
        if (selected != undefined) {
            setRow(selected)
            setOpenEdit(true)
            setModalOpened(true)
        }
    }, [sentInviteCode, rows])

    const handleDetailPanelExpandedRowIdsChange = useCallback(
        (newIds: GridRowId[]) => {
            if (newIds.length > 1)
                setDetailPanelExpandedRowIds([newIds[newIds.length - 1]]);
            else
                setDetailPanelExpandedRowIds(newIds);
        }, []);

    useEffect(() => {
        getPayCodes()
        loadInvites()
    }, [])

    const loadInvites = () => {
        const params: string[] = [];

        if (visitDates[0] != undefined) params.push(`VisitDateFrom=${visitDates[0].toString()}`);
        if (visitDates[1] != undefined) params.push(`VisitDateTo=${visitDates[1].toString()}`);
        if (sentDates[0] != undefined) params.push(`SentDateFrom=${sentDates[0].toString()}`);
        if (sentDates[1] != undefined) params.push(`SentDateTo=${sentDates[1].toString()}`);
        if (selectedCoordinators != undefined && selectedCoordinators.length > 0) {
            params.push(`SentBy=${selectedCoordinators.map((s: any) => s.email).join(',')}`);
        }
        if (selectedStatus != undefined && selectedStatus.length > 0) {
            params.push(`Status=${selectedStatus.toString()}`);
        }

        const queryString = params.length > 0 ? `?${params.join('&')}` : '';

        fetcher.get(`Invite${queryString}`).then(res => {
            setRows(res.data.data);
        });
    }

    useEffect(() => {
        setGroupedRows(GroupInvitesByInviteCode(rows))
    }, [rows])

    const revokeInvokeInvite = (row: any, all: boolean) => {
        let InviteIds: number[] = [];

        if (all)
            row.invites.map((invite: any) => {
                InviteIds.push(invite.inviteId)
            })
        else
            InviteIds.push(row.inviteId)

        invokeFetcher.post(`Invite/invoke`, InviteIds).then(() => {
            showSuccess("Invite has been updated.")
            loadInvites()
        }).catch((error) => {
            const response = error.response?.data;
            showError(response?.message ?? error.message);
        })
    }

    const handleResendInvite = (row: any) => {
        setOpenResend(true)
        setRow(row)
    }

    const handleCloseResend = () => {
        setOpenResend(false)
        setRow([])
    }

    const resendInvite = (row: any) => {
        const payload = {
            visitIds: row.invites ? row.invites.map((invite: any) => {
                return invite.visitId
            }) : [row.visitId]
        }
        post(`Invite/${row.type}/${row.caregiverId}`, payload).then(() => {
            handleCloseResend()
            showSuccess("Invite has been resent.")
        })
    }

    const assignCaregiver = (row: any) => {
        const paycode = payCodes.find(pc => pc.name == selectedPayCodesByVisit[row.visitId])
        const payload = {
            visitId: row.visitId,
            caregiverId: row.caregiverId,
            payCodeId: paycode?.id ?? 0,
            startTime: startTime,
            endTime: endTime,
        }
        assignCarergiverFetcher.post("Visit/newCaregiver", payload).then(() => {
            showSuccess("Caregiver has been assinged.")
            handleCloseModal();
        }).catch((error) => {
            const response = error.response?.data;
            showError(response?.message ?? error.message);
        })
    }

    const getPayCodes = () => {
        get('Billing/payCodes/').then((res: any) => {
            setPayCodes(res.data)
        }).catch((error) => {
            const response = error.response?.data;
            showError(response?.message ?? error.message);
        })
    }

    const handlePayCodeChange = (payCode: any, invite: any) => {
        setSelectedPayCodesByVisit((prevSelectedPayCodes: any) => ({
            ...prevSelectedPayCodes,
            [invite.visitId]: payCode,
        }));
    };


    const customScrollbarStyles = {
        '&::-webkit-scrollbar': {
            width: '7px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#e8e0ff',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#7846ff',
        },
    };
    const formatDeclined = (declined: string) => {
        const DeclineReasons = {
            'far': 'Too Far',
            'timing': 'Timing Conflict',
            'hours': 'Too Many Hours',
            'patient': 'Patient Specific',
            'gender': 'Gender Conflict',
            'location': 'Location Area'
        };

        const declinedArray = declined?.split(',');
        const formatedArray: string[] = [];
        declinedArray?.forEach((decline: any) => {
            const formated = DeclineReasons[decline.trim() as keyof typeof DeclineReasons];
            if (formated)
                formatedArray.push(formated);
        })
        const final = formatedArray.join(' ,');
        return final;
    }

    const headerActions =
        <FlexAlignCenter>
            <Tooltip title={caseBased ? 'Invite Based View' : 'Case Based View'}>
                <IconButton onClick={() => setCaseBased(!caseBased)}>
                    {caseBased ? <ViewListOutlined color="primary" /> : <ViewModuleOutlined color="primary" />}
                </IconButton>
            </Tooltip>
            <Tooltip title='Reload'>
                <IconButton onClick={() => loadInvites()}>
                    <ReplayIcon fontSize='small' color='primary' />
                </IconButton>
            </Tooltip>
            <IconButton onClick={() => setOpenFilters(true)}>
                <TuneOutlined color='primary' fontSize='small' />
            </IconButton>
        </FlexAlignCenter>

    const getStatusBtnColor = (status: string) => {
        switch (status) {
            case 'Accepted':
                return 'success';
            case 'Pending':
                return 'info';
            case 'Declined':
                return 'warning';
            default:
                return 'info';
        }
    }

    const handleTextClick = (row: any) => {
        if (row.chaId) { window.open(`/chat/${row.chatId}`, "_blank"); }
    }

    const getTextTitle = (row: any) => {
        if (row.coordinatorNotSeen)
            return 'You have a unread message for this invite.\nClick to see.'
        else {
            return row.body
        }
    }

    const handleOpenNotes = (row: any) => {
        setRow(row)
        getNotes(row.inviteId)
        setOpenNotes(true)
    }

    const handleCloseNotes = () => {
        setOpenNotes(false)
        setNotes([])
    }

    const SaveNote = () => {
        let newNote: note = {
            Body: noteBody,
            UserId: user?.userId,
            InviteId: row.inviteId
        }
        if (noteType == 'caregiver')
            newNote = { ...newNote, CaregiverId: row.caregiverId, PatientId: null }
        if (noteType == 'patient')
            newNote = { ...newNote, PatientId: row.patientId, CaregiverId: null }

        post('notes/create', newNote).then(res => {
            // setOpenNotes(false)
            handleClickAdd()
            getNotes(row.inviteId);
        })
    }

    const handleDeleteNote = () => [
        get(`notes/delete/${selectedNote.id}`).then(res => {
            setOpenDelete(false)
            getNotes(row.inviteId)
            showSuccess('Note Successfully Deleted.')
        })
    ]

    const handleClickDelete = (note: any) => {
        setSelectedNote(note)
        setOpenDelete(true)
    }

    const handleClickAdd = () => {
        setAddNote(!addNote)
        setNoteBody('')
        setNoteType('')
    }

    const getNotes = (id: any) => {
        get(`notes/invite/${id}`).then((res: any) => {
            setNotes(res.data.data)
        })
    }

    const getPerviousNotes = () => {
        get(`notes/pervious/${row.inviteId}`).then((res: any) => {
            setNotes(res.data.data)
        })
    }

    const handleClickCreate = () => {
        setOpenCreate(true)
    }

    const handleCloseCreate = () => {
        loadInvites();
    }

    const handleOpenStatus = (event: React.MouseEvent<HTMLElement>, row: any) => {
        setRow(row)
        setOpenStatus(true)
        setAnchorEl(event.currentTarget);
    }

    const handleStatusChange = (status: string) => {
        get(`Invite/update-status/${row.inviteId}/${status}`).then(() => {
            showSuccess("Status updated.")
            setOpenStatus(false);
            loadInvites()
        })
    }

    // communication method icon 

    const communicationMethodIcon = (row: any) => (
        <IconButton disabled={!row.chaId} size='small' onClick={() => handleTextClick(row)}>
            <Badge
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
                color={
                    row.coordinatorNotSeen && row.chaId
                        ? 'error'
                        : 'default'
                }
                badgeContent={
                    row.coordinatorNotSeen && row.chaId
                        ? 0
                        : null
                }
            >
                <ChatOutlined
                    fontSize='small'
                    color={!row.chaId ? 'disabled' : row.coordinatorNotSeen ? 'error' : 'primary'}
                />
            </Badge>
        </IconButton>
    );

    const subCols = () => {
        return [
            { field: 'hhaVisitId', flex: 0.04, headerName: 'HHA Visit Id' },
            {
                field: 'visitDateTime', flex: 0.07, headerName: 'Visit Date & Time',
                renderCell: (params: any) => {
                    return (
                        <div>
                            <span>{params.row.visitDateTime}</span>
                            {params.row.schedulePermanency?.toLowerCase() === 'permanent' ?
                                <Tooltip placement="right"
                                    title={
                                        strings.firstLetterUpperCase(params.row.schedulePermanency?.toLowerCase()) + " case, " +
                                        (
                                            params.row.staffingPermanency == "None" ? "has a permanently assigned caregiver" :
                                                "needs a " + (params.row.staffingPermanency == 'Permanent' ? 'permanent' : 'temporary') +
                                                " staffing")
                                            + ('.\nThe caregiver was invited to serve as ' + params.row.invitePermanency)
                                    }
                                >
                                    <Repeat sx={{
                                        height: '11px', width: '12px', fontWeight: 'bold', paddingRight: '4px',
                                        color:
                                            (params.row.staffingPermanency == 'Temporary') ? 'orange' :  // caregiver on assignment is not assigned to shift
                                                (params.row.staffingPermanency == 'Permanent') ? 'red' : 'inherit'
                                    }} />
                                </Tooltip> : null}
                        </div>
                    )
                }
            },
            {
                field: 'status', headerName: 'Response', flex: 0.05, renderCell: (params: any) => {
                    return (
                        <div>
                            <Button
                                sx={{
                                    width: '95px', fontSize: '0.7rem', marginRight: '7px',
                                    color: getStatusColor(params.row.status), borderColor: getStatusColor(params.row.status),
                                    '&:hover': { borderColor: getStatusColor(params.row.status) }
                                }}
                                size='small'
                                onClick={(e) => handleOpenStatus(e, params.row)}
                                variant='outlined'
                            >
                                {params.row.status}
                            </Button>
                            {params.row.acceptedTemporary &&
                                <>
                                    <span>Temp</span>
                                    <Tooltip title={'Accepted temporary' + ('.\nWas invited to serve as ' + params.row.invitePermanency)}>
                                        <IconButton disableRipple>
                                            <InfoOutlined fontSize='small' color='primary' sx={{ width: '15px' }} />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        </div>
                    )
                }
            },
            {
                field: 'visitStatus', headerName: 'Visit Status', flex: 0.04, renderCell: (params: any) => {
                    return (
                        <Button
                            sx={{ width: '95px', fontSize: '0.7rem', color: getStatusColor(params.row.visitStatus), borderColor: getStatusColor(params.row.visitStatus) }}
                            size='small'
                            variant='outlined'
                            style={{
                                pointerEvents: 'none',
                            }}
                        >
                            {params.row.visitStatus}
                        </Button>
                    )
                }
            },
            {
                field: 'revokeInvoke', flex: 0.02, renderCell: (params: any) => {
                    return (
                        <Tooltip title={params.row.isActive ? 'Revoke Invite' : 'Invoke Invite'}>
                            <IconButton onClick={() => revokeInvokeInvite(params.row, false)} >
                                {params.row.isActive ? <CancelRoundedIcon fontSize='small' color={'primary'} /> : <CheckCircle fontSize='small' color={'disabled'} />}
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            { field: 'nothing', flex: 0.05, renderCell: (params: any) => { <></> } }
        ]
    }

    const groupedCols = () => {
        return [
            {
                field: 'inviteCode',
                flex: 0.04,
                headerName: 'Invite Code',
            },
            { field: 'patientName', headerName: 'Patient', navUrl: "patient/{patientId}/details" },
            // {field: 'hhaVisitId', flex: 0.05, headerName: 'HHA Visit Id' },
            // {field: 'visitDateTime', headerName: 'Visit Date & Time' },
            { field: 'caregiverName', headerName: 'Sent to', navUrl: "caregiver/{caregiverId}/details" },
            { field: 'sentBy', headerName: 'Sent By' },
            //{field: 'type', headerName: 'Method'}, // SMS / Email
            {
                field: 'sentOn', headerName: 'Sent On',
                sortComparator: dateSortComparator,
                renderCell: (params: any) => (dateTime.getFormattedDateTime(params.row.sentOn))
            },
            {
                field: 'isActive', headerName: 'Active', flex: 0.03, renderCell: (params: any) => {
                    const activeRows = params.row.invites?.filter((i: any) => i.isActive === true);
                    const hasActiveRows = activeRows.length > 0;
                    return (
                        hasActiveRows ? <CheckCircleOutline fontSize='small' color='primary' /> : <CancelOutlined fontSize='small' color='error' />
                    )
                }
            },
            {
                field: 'response', headerName: 'Reponse', renderCell: (params: any) => {
                    return (
                        <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                            {
                                StatusButton(getGroupedRowStatus(params.row.status))
                            }
                            {params.row.coordinatorContact &&
                                <Tooltip title='Caregiver needs more info on this case'>
                                    <IconButton>
                                        <LiveHelpOutlined fontSize='small' color='primary' />
                                    </IconButton>
                                </Tooltip>
                            }
                        </Box>
                    )
                }
            },
            {
                field: 'visitStatus', headerName: 'Visit Status', renderCell: (params: any) => {
                    return (
                        <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
                            {
                                StatusButton(getGroupedVisitStatus(params.row.visitStatus))
                            }
                        </Box>
                    )
                }
            },
            {
                field: 'declineReasons', headerName: 'Declined Reasons', renderCell: (params: any) => {
                    return (
                        <Tooltip title={formatDeclined(params.row.declineReasons)} placement='bottom'>
                            <div>
                                {formatDeclined(params.row.declineReasons)}
                            </div>
                        </Tooltip>)
                }
            },
            { field: 'clicks', headerName: 'Clicks', flex: 0.02 },
            {
                field: 'icons', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title="Notes">
                            <IconButton size='small' onClick={() => handleOpenNotes(params.row)}>
                                <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} badgeContent={params.row.notes?.length}>
                                    <StickyNote2Outlined fontSize='small' color='primary' />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons1', flex: 0.01, renderCell: (params: any) => {
                    return params.row.chaId ? (
                        <Tooltip title={getTextTitle(params.row)} placement='bottom'>
                            {communicationMethodIcon(params.row)}
                        </Tooltip>
                    ) : (communicationMethodIcon(params.row));
                }
            },
            {
                field: 'icons2', flex: 0.01, renderCell: (params: any) => {
                    const activeRows = params.row.invites?.filter((i: any) => i.isActive === true);
                    const hasActiveRows = activeRows.length > 0;

                    return (
                        <Tooltip title={(hasActiveRows ? 'Revoke' : 'Invoke') + ' all Invites in this case'}>
                            <IconButton size='small' onClick={() => revokeInvokeInvite(params.row, true)} disabled={isLoading}>
                                {hasActiveRows ? <CancelRoundedIcon fontSize='small' color={hasActiveRows ? 'primary' : 'disabled'} /> : <CheckCircle fontSize='small' color={'disabled'} />}
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons3', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title='Resend Invite'>
                            <IconButton size='small' onClick={() => handleResendInvite(params.row)} disabled={!params.row.invites?.some((i: any) => i.status == 'Pending')}>
                                <SendRoundedIcon fontSize='small' color={params.row.invites?.some((i: any) => i.status == 'Pending') ? 'primary' : 'disabled'} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons4', flex: 0.01, renderCell: (params: any) => {
                    const activeRows = params.row.invites?.filter((i: any) => i.isActive === true);
                    const hasActiveRows = activeRows.length > 0;

                    return (
                        <Tooltip title='Assign Caregiver'>
                            <IconButton size='small' onClick={() => handleOpenModal(params.row)} disabled={params.row.status == 'Declined' || !hasActiveRows}>
                                <CheckIcon fontSize='small' color={params.row.status != 'Declined' && !!hasActiveRows ? 'primary' : 'disabled'} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons5', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title='Edit Invite'>
                            <IconButton size='small' onClick={() => { setOpenEdit(true); setRow(params.row); }}>
                                <Edit fontSize='small' color={'primary'} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
        ]
    }

    const ungroupedCols = () => {
        return [
            { field: 'patientName', headerName: 'Patient', navUrl: "patient/{patientId}/details" },
            { field: 'hhaVisitId', flex: 0.05, headerName: 'HHA Visit Id' },
            { field: 'visitDateTime', headerName: 'Visit Date & Time' },
            { field: 'caregiverName', headerName: 'Sent to', navUrl: "caregiver/{caregiverId}/details" },
            { field: 'sentBy', headerName: 'Sent By' },
            //{field: 'type', headerName: 'Method'}, // SMS / Email
            { field: 'sentOn', headerName: 'Sent On', renderCell: (params: any) => (dateTime.getFormattedDateTime(params.row.sentOn)) },
            {
                field: 'status', headerName: 'Response', renderCell: (params: any) => {
                    return (
                        <Box display={'flex'} justifyContent={'space-between'}>
                            <Button
                                sx={{ width: '95px', margin: '0px 5px', fontSize: '0.7rem', color: getStatusColor(params.row?.status), borderColor: getStatusColor(params.row?.status) }}
                                size='small'
                                variant='outlined'
                                style={{
                                    pointerEvents: 'none',
                                }}
                            >
                                {params.row?.status}
                            </Button>
                        </Box>
                    )
                }
            },
            { field: 'clicks', headerName: 'Total Clicks', flex: 0.04 },
            {
                field: 'icons', flex: 0.06, renderCell: (params: any) => {
                    return (
                        <Tooltip title="Notes">
                            <IconButton onClick={() => handleOpenNotes(params.row)}>
                                <Badge anchorOrigin={{ horizontal: 'left', vertical: 'top' }} badgeContent={params.row.notes?.length}>
                                    <StickyNote2Outlined fontSize='small' color='primary' />
                                </Badge>
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons1', flex: 0.01, renderCell: (params: any) => {
                    return params.row.chaId ? (
                        <Tooltip title={getTextTitle(params.row)} placement='bottom'>
                            {communicationMethodIcon(params.row)}
                        </Tooltip>
                    ) : (communicationMethodIcon(params.row));
                }
            },
            {
                field: 'icons2', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title={(params.row.isActive ? 'Revoke' : 'Invoke') + ' Invite'}>
                            <IconButton onClick={() => revokeInvokeInvite(params.row, false)} disabled={isLoading}>
                                {params.row.isActive ? <CancelRoundedIcon fontSize='small' color={params.row?.isActive ? 'primary' : 'disabled'} /> : <CheckCircle fontSize='small' color={'disabled'} />}
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons3', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title='Resend Invite'>
                            <IconButton onClick={() => handleResendInvite(params.row)} disabled={params.row?.status != 'Pending'}>
                                <SendRoundedIcon fontSize='small' color={params.row?.status == 'Pending' ? 'primary' : 'disabled'} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
            {
                field: 'icons4', flex: 0.01, renderCell: (params: any) => {
                    return (
                        <Tooltip title='Assign Caregiver'>
                            <IconButton onClick={() => handleOpenModal(params.row)} disabled={params.row.status == 'Declined'}>
                                <CheckIcon fontSize='small' color={params.row.status != 'Declined' ? 'primary' : 'disabled'} />
                            </IconButton>
                        </Tooltip>
                    )
                }
            },
        ]
    }

    const DetailPanel = (row: any) => {
        return (
            <Box margin={'10px'}>
                <DataGrid
                    title={'Invites'}
                    data={{
                        columns: subCols(),
                        rows: row.row.invites
                    }}
                    autoHeight
                    options={{
                        hideFooter: true,
                        headerHeight: 25,
                        hideToolbarHeader: true,
                        pagination: false,
                        hideToolbarIconText: true,
                        hideExport: true,
                    }}
                />
            </Box>
        )
    }

    const AssignItem = (invite: any) => {
        return (
            <ListItem sx={{ height: '65px', display: 'flex', justifyContent: 'space-between' }} key={invite.visitId} disablePadding >
                <ListItemText
                    primary={`${invite.visitDateTime}`}
                    primaryTypographyProps={{ fontSize: '14px' }}
                    secondary={invite?.status?.toUpperCase()}
                    secondaryTypographyProps={{ color: getStatusColor(invite.status) }}
                />
                <Autocomplete
                    onChange={(e, option) => { handlePayCodeChange(option, invite) }}
                    size='small'
                    getOptionLabel={(option) => option}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            sx={{ width: '155px' }}
                            value={selectedPayCodesByVisit[invite.visitId]}
                            label={<BoldTitle fontWeight='200px'>Select Paycode</BoldTitle>}
                        />}
                    options={payCodes.map((paycodes: any) => paycodes.name)}
                />
                <Button
                    disabled={
                        assignCarergiverFetcher.isLoading || 
                        invite.visitStatus == 'Taken' || invite.visitStatus == 'Assigned' || 
                        !selectedPayCodesByVisit[invite.visitId] ||
                        !invite.isActive
                    }
                    variant="outlined"
                    sx={{ marginLeft: '10px' }}
                    onClick={() => assignCaregiver(invite)}
                >
                    {invite.visitStatus == 'Taken' || invite.visitStatus == 'Assigned' ? 'Assigned' : 'Assign'}
                </Button>
            </ListItem>
        )
    }


    const getDetailPanelContent = useCallback<
        NonNullable<DataGridProProps['getDetailPanelContent']>
    >(({ row }) => <DetailPanel row={row} />, []);

    const getDetailPanelHeight = useCallback(() => 'auto', []);

    return (
        <Box>
            <Loader isLoading={fetcher.isLoading} />
            <div style={{ height: '50px' }}>
                <AddFab title={'Create Invite'} clickAction={() => handleClickCreate()} />
            </div>
            {caseBased && <DataGrid
                title={'Invites'}
                data={{
                    columns: groupedCols(),
                    rows: groupedRows
                }}
                options={{
                    rowHeight: 45,
                    hideToolbarIconText: true,
                    toolbarComponent: headerActions,
                    hideExport: true,
                    hideFiltering: true,
                }}
                getDetailPanelContent={getDetailPanelContent}
                getDetailPanelHeight={getDetailPanelHeight}
                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            />}
            {!caseBased && <DataGrid
                title={'Invites'}
                data={{
                    columns: ungroupedCols(),
                    rows: rows
                }}
                options={{
                    hideToolbarIconText: true,
                    toolbarComponent: headerActions,
                    hideExport: true,
                    hideFiltering: true,
                }}
            />}
            <InviteFilters
                setQuery={setQuery}
                getInvites={loadInvites}
                open={openFilters}
                setOpenFilters={setOpenFilters}
                sentDates={sentDates}
                setSentDates={setSentDates}
                visitDates={visitDates}
                setVisitDates={setVisitDates}
                selectedCoordinator={selectedCoordinators}
                setSelectedCoordinator={setSelectedCoordinators}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
            />

            {/* -----Assign Caregiver----- */}
            <NewModal
                open={modalOpen}
                title={"Assign Caregiver"}
                height="500px"
                hideBtn={true}
                onSave={() => assignCaregiver(row)}
                onClose={() => handleCloseModal()}
            >
                <Box>
                    <div>
                        <Bold fontFamily={'ConnectAideSecondary'} fontSize="20px" >
                            Assign {row?.caregiverName} to this case?
                        </Bold>
                    </div>
                    <div style={{ display: 'block', padding: "10px 0" }}>
                        <BoldTitle fontFamily={'ConnectAideSecondary'} fontWeight='400' fontSize='16px'>
                            Patient : {row?.patientName}
                        </BoldTitle>
                        <Paper sx={{ padding: '10px' }} elevation={0}>
                            <List sx={{ height: '300px', marginTop: '20px', overflowY: 'scroll', ...customScrollbarStyles }} disablePadding>
                                {row && row.invites ? (
                                    // If row has invites, map through them
                                    row.invites.map((invite: any) => (
                                        AssignItem(invite)
                                    ))
                                ) : (
                                    AssignItem(row)
                                )}
                            </List>
                        </Paper>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <div>
                                <Bold>From</Bold>
                                <TimePicker
                                    onChange={(e) => setStartTime(e)}
                                    value={startTime}
                                    renderInput={(params) =>
                                        <TextField
                                            sx={{ margin: '5px' }}
                                            defaultValue={new Date().setHours(-6)}
                                            {...params}
                                        />}
                                />
                            </div>
                            <Bold>To</Bold>
                            <TimePicker
                                onChange={(e) => setEndTime(e)}
                                value={endTime}
                                renderInput={(params) =>
                                    <TextField
                                        sx={{ margin: '5px' }}
                                        {...params}
                                    />}
                            />
                        </LocalizationProvider> */}
                    </div>
                    {/* <div style={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                        <Button
                            disabled={!selectedPayCode}
                            onClick={() => assignCaregiver(row)}
                            variant='outlined'
                            sx={{
                                float: 'right',
                                margin: '10px'
                            }}
                        >
                            {'Assign'}
                        </Button>
                    </div> */}
                </Box>
            </NewModal>

            {/* ----- Notes ----- */}
            <NewModal height='90%' top='10%' width='50%' left='30%' title={`Notes`} open={openNotes} hideBtn onClose={() => handleCloseNotes()} onSave={() => { }}>
                <Box>
                    <List sx={{ height: '67vh', overflowY: 'scroll', ...customScrollbarStyles }}>
                        <ListItemButton sx={{ minWidth: '150px', margin: '5px', borderRadius: '2px', background: '#e8e0ff' }} onClick={() => handleClickAdd()}>
                            <ListItemText
                                secondaryTypographyProps={{ textAlign: 'center' }}
                                secondary='Add Note' />
                        </ListItemButton>
                        {notes?.map((note: any) =>
                            <ListItemButton
                                disableRipple
                                disableTouchRipple
                                sx={{
                                    cursor: 'text',
                                    minWidth: '150px',
                                    margin: '5px',
                                    borderRadius: '5px',
                                    border: '2px solid #e8e0ff',
                                    background: '#e8e0ff',
                                    '&:Hover': {
                                        background: '#e8e0ff',
                                    }
                                }}
                            >
                                <ListItemText
                                    primaryTypographyProps={{ fontSize: '15px' }}
                                    primary={note.body}
                                    secondary={`${note.userName} - ${dateTime.getDisplayValue(note.createdOn)}`}
                                    secondaryTypographyProps={{ fontSize: '12px' }}
                                />
                                <IconButton onClick={() => handleClickDelete(note)}>
                                    <Delete fontSize='small' color='primary' />
                                </IconButton>
                            </ListItemButton>)}
                        {notes?.length < 1 && <ListItemText primary="No Notes" primaryTypographyProps={{ marginTop: '25px', textAlign: 'center' }} />}
                    </List>
                    <Button size='small' sx={{ position: 'absolute', bottom: '5px', fontSize: '10px' }} onClick={() => getPerviousNotes()}>Show Previous Notes</Button>
                </Box>
            </NewModal>

            {/* ----- New Note ----- */}
            <NewModal title='Add Note' open={addNote} onClose={() => setAddNote(false)} onSave={() => SaveNote()}>
                <Box>
                    <Typography>Body</Typography>
                    <TextareaAutosize
                        onChange={(e: any) => setNoteBody(e.target.value)}
                        value={noteBody}
                        maxRows={5}
                        minRows={5}
                        style={{
                            maxWidth: '455px',
                            minWidth: '455px',
                            borderRadius: '5px',
                            fontFamily: 'ConnectAideSecondary',
                            padding: '5px'
                        }}
                    />
                    <RadioGroup sx={{ justifyContent: 'center' }} value={noteType} row onChange={(e: any) => setNoteType(e.target.value)}>
                        <FormControlLabel value={'caregiver'} label={'Caregiver'} control={<Radio />} />
                        <FormControlLabel value={'patient'} label={'Patient'} control={<Radio />} />
                    </RadioGroup>
                </Box>
            </NewModal>

            {/* ----- Delete Note ----- */}
            <NewModal title='Delete Note' btnTitle='Delete' height='200px' open={deleteNote} onClose={() => setOpenDelete(false)} onSave={() => handleDeleteNote()}>
                <Box>
                    <Typography>Are you sure you want to delete this note?</Typography>
                </Box>
            </NewModal>

            {/* ----- Resend Invite ----- */}
            <NewModal title='Confirm Resend' btnTitle='Send' height='200px' open={openResend} onClose={() => handleCloseResend()} onSave={() => resendInvite(row)}>
                <Box>
                    <Typography>Are you sure you want to resend this invite?</Typography>
                </Box>
            </NewModal>

            {/* ----- Create Invite ----- */}
            <SendInvite open={openCreate} setOpen={setOpenCreate} onSave={() => handleCloseCreate()} />

            {/* ----- Status Change ----- */}
            <InviteStatusEdit openStatus={openStatus} setOpenStatus={setOpenStatus} anchorEl={anchorEl} handleStatusChange={handleStatusChange} />

            {/* ----- Edit Invite ----- */}
            <EditInvite fetch={loadInvites} setRow={setRow} inviteCode={row.inviteCode} open={openEdit} setOpen={setOpenEdit} />
        </Box>

    )
}

export default Invites
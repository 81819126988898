import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField, Grid, Typography, IconButton, Autocomplete } from '@mui/material';
import NewModal from '../../components/ui/NewModal';
import EmailField from '../../components/form/EmailField/EmailField';
import AddressPicker, { AddressType } from '../../components/form/AddressPicker/AddressPicker';
import PhoneField from '../../components/form/PhoneField/PhoneField';
import GenderField from '../../components/form/GenderField/GenderField';
import EthnicityField from '../../components/form/EthnicityField/EthnicityField';
import LanguagesField from '../../components/form/LanguagesField/LanguagesField';
import DisciplineField from '../../components/form/DisciplineField/DisciplineField';
import DropdownButton from '../../components/common/OptionButton/OptionButton';
import OptionButton from '../../components/common/OptionButton/OptionButton';
import useFetcher from '../../hooks/useFetcher';
import Loader from '../../components/ui/Loader';
import useToast from '../../hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { PersonAddAlt1Outlined } from '@mui/icons-material';
import { CoordinatorOption } from '../../components/form/Filters/VisitFilter';
import { ClearIcon } from '@mui/x-date-pickers-pro';

interface ContactEditProps {
    open: boolean;
    onSave: (data: ContactFormData) => void;
    onClose: () => void;
    formData?: ContactFormData | undefined;
    module: string;
}

export interface ContactFormData {
    rowId: number;
    hhxId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    dateOfBirth: string;
    phone: string;
    gender: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    status?: string;
    languages: string[];
    disciplines: string[];
    ethnicity: string;
    coordinators?: CoordinatorOption[]
}

const ContactEdit: React.FC<ContactEditProps> = ({ open, onSave, onClose, formData, module }) => {
    const [data, setData] = useState<ContactFormData>(formData as ContactFormData);
    const fetcher = useFetcher();
    const { showError, showSuccess } = useToast()
    const coordinatorsFetcher = useFetcher("filter/coordinators");
    const [selectedCoordinator, set_selectedCoordinator] = useState<CoordinatorOption>();
    const [selectedCoordinatorName, set_selectedCoordinatorName] = useState<string>('');

    // coordinator
    const [coordinators, setCoordinators] = React.useState<CoordinatorOption[]>([]);

    useEffect(() => {
        const newData = formData as ContactFormData;
    }, [formData])
    useEffect(() => {
        const currCoord = coordinators?.find((coord: CoordinatorOption) => !!data?.coordinators?.length && coord.name == data?.coordinators[0].name);
        if (!!currCoord)
        {
            set_selectedCoordinator(currCoord);
            set_selectedCoordinatorName(currCoord.name);
        }
    }, [data, coordinators, selectedCoordinator])
    useEffect(() => {
        setCoordinators(coordinatorsFetcher.data || []);
    }, [coordinatorsFetcher.data])
    
    const handleSave = () => {
        if (!data)
            return;

        const payload = {
            ...data,
            address: {
                ...data
            }
        }
        fetcher.post(module.toLowerCase() + "/save", payload).then((response) => {
            const row = response.data.data as ContactFormData;
            showSuccess(module + "Saved!");
            if (typeof onSave === 'function') {
                onSave(data as ContactFormData);
            }
            window.location.href = `/${module.toLowerCase()}/${row.rowId}/details`;
        }).catch(() => {
            if (!payload.firstName || !payload.lastName)
                showError("First & Last name is required.")
            else
                showError("We ran into an issue")
        });
    };

    useEffect(() => {
        if (!open) setData({} as ContactFormData);
        else setData(formData as ContactFormData);
    }, [open]);

    const updateField = (field: keyof ContactFormData, value: unknown) => {
        const data_local = {
            ...data,
            [field]: value,
        } as ContactFormData;
        setData(data_local);
    }

    const handleChange = (field: keyof ContactFormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
        updateField(field, event.target.value);
    };

    const handleSelectChange = (field: keyof ContactFormData) => (event: React.ChangeEvent<{ value: unknown }>) => {
        updateField(field, event.target.value);
    };

    function updateAddress(address: AddressType): void {
        const data_local = {
            ...data,
            ...address,
        } as ContactFormData;
        setData(data_local);
    }

    //function handleCoordinatorChange(field: keyof ContactFormData, valueArr: (CoordinatorOption | undefined)[]) {
    //updateField(field, valueArr);
    function handleCoordinatorChange(field: keyof ContactFormData, value: (CoordinatorOption | undefined)) {
        updateField(field, [value]);
    }

    return (
        <NewModal
            open={open}
            title={`${!data?.rowId ? "New" : "Edit"} ${module}`}
            top={'50px'}
            height="590px"
            onSave={() => handleSave()}
            onClose={() => onClose()}
        >
            <Box sx={{ paddingTop: '25px' }}>
                <Loader isLoading={fetcher.isLoading} />
                {/* <Typography variant="subtitle2">Caregiver Code: {data.caregiverCode}</Typography>
                <Typography variant="subtitle2">Caregiver ID: {data.caregiverId}</Typography> */}
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <TextField
                            label="First Name"
                            fullWidth
                            variant="outlined"
                            value={data?.firstName}
                            onChange={handleChange('firstName')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="disable"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Middle Name"
                            fullWidth
                            variant="outlined"
                            value={data?.middleName}
                            onChange={handleChange('middleName')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="disable"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Last Name"
                            fullWidth
                            variant="outlined"
                            value={data?.lastName}
                            onChange={handleChange('lastName')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="disable"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <EmailField
                            value={data?.email} onChange={handleChange('email')}
                            autoComplete='disable'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Date of Birth"
                            type={"date"}
                            fullWidth
                            variant="outlined"
                            value={data?.dateOfBirth}
                            onChange={handleChange('dateOfBirth')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            autoComplete="disable"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <PhoneField
                            value={data?.phone} setValue={(val) => updateField('phone', val)}
                            autoComplete='disable'
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <GenderField
                            value={data?.gender || ''} onChange={handleChange('gender')}
                            autoComplete="disable"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AddressPicker
                            value={{
                                addressLine1: data?.addressLine1 ?? '',
                                addressLine2: data?.addressLine2 ?? '',
                                city: data?.city ?? '',
                                state: data?.state ?? '',
                                zipCode: data?.zipCode ?? '',
                            }}
                            onChange={(field, value) => updateField(field, value)}
                            autoComplete="disable"
                            onUpdate={updateAddress} />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <LanguagesField />
                    </Grid> */}
                    <Grid item xs={6}>
                        <DisciplineField
                            value={data?.disciplines || []} onChange={handleSelectChange('disciplines')}
                            autoComplete={'disable'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EthnicityField
                            value={data?.ethnicity || ''} onChange={handleChange('ethnicity')}
                            autoComplete={'disable'}
                        />
                    </Grid>
                    {module.toLowerCase() === 'patient' &&
                        <Grid item xs={12}>
                            <Autocomplete
                                // sx={{
                                //     "& .MuiOutlinedInput-root": {
                                //         padding: '30px 30px 40px 0px',
                                //         paddingTop: '20px',
                                //     }
                                // }}

                                //multiple
                                //onChange={(e, option: CoordinatorOption) => { handleCoordinatorChange('coordinators', option.map((o: CoordinatorOption) => coordinators?.find((of: CoordinatorOption) => of.name == o))) }}
                                onChange={(e, option: string) => {
                                    const currCoord = coordinators?.find((coord: CoordinatorOption) => coord.name == option) ?? { id: '0', name: '' } as CoordinatorOption;
                                    const toSet = { id: currCoord?.id, name: currCoord?.name } as CoordinatorOption;
                                    handleCoordinatorChange('coordinators', toSet);
                                }}
                                clearOnEscape
                                clearIcon={<ClearIcon />}
                                disableClearable
                                filterSelectedOptions
                                size='small'
                                getOptionLabel={(option) => option}
                                value={selectedCoordinatorName}
                                renderInput={(params) => <TextField
                                    {...params}
                                    label="Coordinator"
                                />}
                                options={['', ...coordinators.map((coordinator: CoordinatorOption) => coordinator.name)]}
                            />
                        </Grid>
                    }
                </Grid>
            </Box>
        </NewModal>
    );
};

export default ContactEdit;

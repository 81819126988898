import React, { useEffect, useRef, useState } from 'react';
import { TextField, Box, Grid, MenuItem, Paper, ListItem, List, Popper } from '@mui/material';
import UsStateField from '../UsStateField/UsStateField';
import ZipCodeField from '../ZipCodeField/ZipCodeField';
import debounce from 'debounce';
import { MAPBOX_TOKEN } from '../../../data/constants';

// Debounced fetchSuggestions function
const debouncedFetchSuggestions = debounce(async (query: string, setSuggestions: React.Dispatch<React.SetStateAction<any[]>>) => {
    if (MAPBOX_TOKEN) {
        try {
            const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=us`);
            const data = await response.json();
            setSuggestions(data.features);
        } catch (error) {
            console.error("Error fetching suggestions:", error);
        }
    }
}, 1000); // Adjust debounceMs here if needed

export interface AddressType {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
}
interface AddressPickerProps {
    value: AddressType;
    onChange: (field: keyof AddressPickerProps['value'], value: string) => void;
    onUpdate: (address: AddressType) => void;
    autoComplete?: string | undefined;
    //autoComplete?: 'on' | 'off' | 'name' | 'honorific-prefix' | 'given-name' | 'additional-name' | 'family-name' | 'honorific-suffix' | 'nickname' | 'email' | 'username' | 'new-password' | 'current-password' | 'one-time-code' | 'organization-title' | 'organization' | 'street-address' | 'address-line1' | 'address-line2' | 'address-line3' | 'address-level4' | 'address-level3' | 'address-level2' | 'address-level1' | 'country' | 'country-name' | 'postal-code' | 'cc-name' | 'cc-given-name' | 'cc-additional-name' | 'cc-family-name' | 'cc-number' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-csc' | 'cc-type' | 'transaction-currency' | 'transaction-amount' | 'language' | 'bday' | 'bday-day' | 'bday-month' | 'bday-year' | 'sex' | 'tel' | 'tel-country-code' | 'tel-national' | 'tel-area-code' | 'tel-local' | 'tel-local-prefix' | 'tel-local-suffix' | 'tel-extension' | 'impp' | 'url' | 'photo';

    // address validation
    validate?: (field: keyof AddressPickerProps['value'], value: string) => boolean;
    debounceMs?: number;
}

const AddressPicker: React.FC<AddressPickerProps> = ({
    value,
    onChange,
    onUpdate,
    validate,
    autoComplete,
    debounceMs = 1000,
}) => {
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const addressLine1Ref = useRef<HTMLInputElement>(null);

    const handleChange = (field: keyof AddressPickerProps['value']) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        onChange(field, newValue);

        if (autoComplete && (field === 'addressLine1' || field === 'city' || field === 'zipCode')) {
            // Combine relevant fields for more accurate suggestions
            const combinedQuery = `
                ${field === 'addressLine1' ? newValue : value.addressLine1}, 
                ${field === 'city' ? newValue : value.city}, 
                ${value.state}, 
                ${field === 'zipCode' ? newValue : value.zipCode}`;
            debouncedFetchSuggestions(combinedQuery, setSuggestions);
        }
    };

    useEffect(() => {
        if (!autoComplete) {
            setSuggestions([]);
        }
    }, [autoComplete]);

    return (
        <Box>
            <Grid container spacing={1}>
                <Grid item xs={9}>
                    <TextField
                        label="Address Line 1"
                        fullWidth
                        variant="outlined"
                        value={value.addressLine1}
                        onChange={handleChange('addressLine1')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputRef={addressLine1Ref}
                        autoComplete={autoComplete}
                        error={validate ? !validate('addressLine1', value.addressLine1) : false}
                    />
                    <Popper
                        open={suggestions.length > 0}
                        anchorEl={addressLine1Ref.current}
                        placement="bottom-start"
                        style={{ width: addressLine1Ref.current?.offsetWidth, zIndex: 1300 }}
                        modifiers={[
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 0],
                                },
                            },
                        ]}
                    >
                        <Paper>
                            <List>
                                {suggestions.map((suggestion, index) => (
                                    <ListItem
                                        button
                                        key={index}
                                        onClick={() => {
                                            const { place_name, geometry, context, address, text, properties } = suggestion;
                                            const addressLine1 = !!properties.address ? properties.address :
                                                !!address && !!text ? address + ' ' + text : value.addressLine1
                                            const city = 
                                                context.find((c: any) => c.id.startsWith('locality'))?.text || 
                                                context.find((c: any) => c.id.startsWith('place'))?.text ||
                                                value.city;
                                            const state = context.find((c: any) => c.id.startsWith('region'))?.short_code.split('-')[1] || value.state;
                                            const zipCode = context.find((c: any) => c.id.startsWith('postcode'))?.text || value.zipCode;

                                            const addressData = {
                                                addressLine1: addressLine1,
                                                city: city,
                                                state: state,
                                                zipCode: zipCode,
                                            } as AddressType;
                                            onUpdate(addressData);
                                            // for future when we want to start saving coordinates
                                            // onChange('coordinates', {
                                            //     latitude: geometry.coordinates[1],
                                            //     longitude: geometry.coordinates[0],
                                            // });
                                            setSuggestions([]);
                                        }}
                                    >
                                        {suggestion.place_name}
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Popper>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Line 2"
                        placeholder="APT / FL"
                        fullWidth
                        variant="outlined"
                        value={value.addressLine2}
                        onChange={handleChange('addressLine2')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        autoComplete={autoComplete}
                        error={validate ? !validate('addressLine2', value.addressLine2) : false}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="City"
                        fullWidth
                        variant="outlined"
                        value={value.city}
                        onChange={handleChange('city')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        autoComplete={autoComplete}
                        error={validate ? !validate('city', value.city) : false}
                    />
                </Grid>
                <Grid item xs={3}>
                    <UsStateField
                        value={value.state || ''}
                        onChange={handleChange('state')}
                        autoComplete={autoComplete}
                    />
                </Grid>
                <Grid item xs={3}>
                    <ZipCodeField
                        value={value.zipCode}
                        onChange={handleChange('zipCode')}
                        autoComplete={autoComplete}
                        error={validate ? !validate('zipCode', value.zipCode) : false}
                    />
                </Grid>
            </Grid>

        </Box>
    );
};

export default AddressPicker;
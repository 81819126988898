import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import NewModal from '../../components/ui/NewModal';
import useFetcher from '../../hooks/useFetcher';
import { Grid, FormControl, InputLabel } from '@mui/material';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

interface Preference {
    id: number;
    name: string;
    value: number | string;
}

interface GeneralPreference {
    id: number;
    preferences: Preference[];
}
interface EditAdditionalPreferencesDialogProps {
    open: boolean;
    onClose: () => void;
    additionalPreferences: GeneralPreference;
    setAdditionalPreferences: (prefs: GeneralPreference) => void;
    refModule: string;
    refRowId: string;
}

const EditAdditionalPreferences: React.FC<EditAdditionalPreferencesDialogProps> = ({ open, onClose, additionalPreferences, setAdditionalPreferences, refModule, refRowId }) => {
    const [maxCaseHours, setMaxCaseHours] = useState<number | string>(0);
    const [maxTravelTime, setMaxTravelTime] = useState<number | string>(0);
    const [preferredGender, setPreferredGender] = useState<string>('');
    const [preferredCommunicationMethod, setPreferredCommunicationMethod] = useState<string>('');
    const [languageFlexibility, setLanguageFlexibility] = useState<string>('');
    const [notes, setNotes] = useState<string>('');

    // params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token');
    const preferenceFetcher = useFetcher(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, token);

    useEffect(() => {
        const maxTravelTimePref = additionalPreferences.preferences.find(p => p.name === 'Max Travel Time');
        const maxCaseHoursPref = additionalPreferences.preferences.find(p => p.name === 'Max Case Hours');
        const preferredGenderPref = additionalPreferences.preferences.find(p => p.name === 'Preferred Gender');
        const preferredCommunicationMethodPref = additionalPreferences.preferences.find(p => p.name === 'Preferred Communication Method');
        const languageFlexibilityPref = additionalPreferences.preferences.find(p => p.name === 'Language Flexibility');
        const notesPref = additionalPreferences.preferences.find(p => p.name === 'Notes');

        if (!!maxCaseHoursPref) setMaxCaseHours(maxCaseHoursPref.value);
        if (!!maxTravelTimePref) setMaxTravelTime(maxTravelTimePref.value);
        if (!!preferredGenderPref) setPreferredGender(preferredGenderPref.value as string);
        if (!!preferredCommunicationMethodPref) setPreferredCommunicationMethod(preferredCommunicationMethodPref.value as string);
        if (!!languageFlexibilityPref) setLanguageFlexibility(languageFlexibilityPref.value as string);
        if (!!notesPref) setNotes(notesPref.value as string);
    }, [additionalPreferences]);

    useEffect(() => {
        console.log(preferredCommunicationMethod)
        console.log(preferredGender)
        console.log(maxTravelTime)
        console.log(notes)
        console.log(maxCaseHours)
    }, [preferredCommunicationMethod, preferredGender, maxTravelTime, maxCaseHours, notes])

    const handleSave = async () => {
        let updatedPreferences = { ...additionalPreferences };

        updatedPreferences.preferences = updatedPreferences.preferences.map(pref => {
            if (pref.name === 'Max Case Hours') return { ...pref, value: maxCaseHours };
            if (pref.name === 'Max Travel Time') return { ...pref, value: maxTravelTime };
            if (pref.name === 'Preferred Gender') return { ...pref, value: preferredGender };
            if (pref.name === 'Preferred Communication Method') return { ...pref, value: preferredCommunicationMethod };
            if (pref.name === 'Language Flexibility') return { ...pref, value: languageFlexibility };
            if (pref.name === 'Notes') return { ...pref, value: notes };
            return pref;
        });

        try {
            const response = await preferenceFetcher.post(`preference/personal/${refModule}/${refRowId}`, updatedPreferences);
            setAdditionalPreferences(response.data);
            toast.success("Saved");
            onClose();
        } catch (err) {
            console.error('Error updating preferences:', err);
        } finally {
        }
    };

    return (
        <NewModal top={'70px'} title="Edit Additional Preferences" open={open} onClose={onClose} onSave={handleSave} height='590px'>
            <Grid container spacing={2}>
                {
                    refModule === 'caregiver' &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Max Case Hours"
                            type="number"
                            value={maxCaseHours}
                            onChange={(e) => {
                                setMaxCaseHours(e.target.value)
                            }}
                            inputProps={{ min: 0, max: 24 }}
                        />
                    </Grid>
                }
                {
                    refModule === 'caregiver' &&
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Max Travel Time"
                            type="number"
                            value={maxTravelTime}
                            onChange={(e) => setMaxTravelTime(e.target.value)}
                            inputProps={{ min: 0, max: 180 }}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel>Preferred Gender</InputLabel>
                        <Select
                            label={"Preferred Gender"}
                            value={preferredGender}
                            onChange={(e) => setPreferredGender(e.target.value as string)}
                        >
                            <MenuItem value="">
                                <em>Select</em>
                            </MenuItem>
                            <MenuItem value="Any">Any</MenuItem>
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {refModule === 'caregiver' &&
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Preferred Communication Method</InputLabel>
                            <Select
                                label={"Preferred Communication Method"}
                                value={preferredCommunicationMethod}
                                onChange={(e) => setPreferredCommunicationMethod(e.target.value as string)}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="SMS">SMS</MenuItem>
                                <MenuItem value="Phone">Phone</MenuItem>
                                <MenuItem value="Email">Email</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {refModule === 'caregiver' &&
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Language Flexibility</InputLabel>
                            <Select
                                label={"Language Flexibility"}
                                value={languageFlexibility}
                                onChange={(e) => setLanguageFlexibility(e.target.value as string)}
                            >
                                <MenuItem value="">
                                    <em>Select</em>
                                </MenuItem>
                                <MenuItem value="Any">I'm okay with any language</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                }
                {
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                value={notes}
                                label={"Notes"}
                                onChange={(e) => { setNotes(e.target.value) }}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                InputProps={{
                                    sx: {
                                        padding: 0,
                                        '& .MuiOutlinedInput-input': {
                                            padding: '4px 8px',
                                            fontSize: '14px',
                                            whiteSpace: 'nowrap',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            padding: 0,
                                        },
                                        '& textarea': {
                                            overflow: 'auto',
                                            '&::-webkit-scrollbar': {
                                                width: '6px',
                                            },
                                            '&::-webkit-scrollbar-thumb': {
                                                backgroundColor: '#888',
                                                borderRadius: '2px',
                                            },
                                            '&::-webkit-scrollbar-thumb:hover': {
                                                backgroundColor: '#555',
                                            },
                                            '&::-webkit-scrollbar-button': {
                                                display: 'none',
                                            },
                                        },
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                }
            </Grid>
        </NewModal>
    );
};

export default EditAdditionalPreferences;
import { numbers } from './numbers'
import { arrays } from './arrays';
import { HDate } from '@hebcal/core';
import { Hd } from '@mui/icons-material';
import hy from 'date-fns/esm/locale/hy';
import moment from 'moment';
export const dateTime = {
    parseTime,
    formatTimeAMPM,
    formatTime24,
    getYearDate,
    getDateTime,
    convertStringsMMDDYYYYToYYYYMMDD,
    getUSAFormattedDateString,
    getFormattedDateTime,
    getDisplayValue,
    addDays,
    parseTimeToDate,
    getTimeFromDateString,
    getDay,
    pad,
    getWeekdayFromNum,
    getWeekdaysString,
    getMonthDaysString,
    applyDate,
    applyTime,
    getYearDateFromDateTime,
    getCurrentFormattedDateTime,
    getDateObject,
    isDateObject,
    getAge,
    isDateDefaultValue,
    filterDefaultDate,
    replaceEmptyDatesWithDefault,
    formatStartAndEndTime,
    formatStartAndEndDate,
    dateTimeRangeOverlaps,
    getBeginningOfWeek,
    getBeginningOfMonth,
    getNumberOfDaysBetweenDates,
    isCurrentWeek,
    isToday,
    isDatePast,
    getNumberOfWeeksBetweenDates,
    getDaysUntilNextWeekday,
    getNumberOfMonthsBetweenDates,
    getDaysUntilNextMonthDay,
    combineIntervals,
    closeWindows,
    isValidHhMm,
    isValidTime,
    isValidDate,
    getDateOnly,
    isGreaterThanToday,
    isDateOnlyEqual,
    getDateString,
    getTimeString,
    getTimeStringNew,
    getShortMonthAndDay,
    getDateWithDay,
    getDate,
    getShortDisplayDate,
    getTotalHours,
    getDayAndDate
};

export const getISODate = (date) => {
    let newDate = new Date(date);
    return newDate.toISOString();
}

/**
 * 
 * @param {*} hebMonthYear - date representing month/year in hebrew
 * @returns respective Month range in English like fromDate and toDate.
 */
export const getMonthRangeFromHebrew = (hebMonthYear) => {
    let fromDateHeb = new HDate(1, hebMonthYear.month.value, hebMonthYear.year.value);
    let toDateHeb = new HDate(fromDateHeb.daysInMonth(), hebMonthYear.month.value, hebMonthYear.year.value);
    let fromDateEng = fromDateHeb.greg();
    fromDateEng = dateTime.getUSAFormattedDateString(fromDateEng);
    let toDateEng = toDateHeb.greg();
    toDateEng = dateTime.getUSAFormattedDateString(toDateEng);
    return {
        fromDate: fromDateEng,
        toDate: toDateEng
    }
}

/**
 *
 * @param {*} date - JS date object
 * @returns respective Month range in English like fromDate and toDate.
 */
export const getHebrewMonthRangeFromRegDate = (date) => {
    if (!date)
        return;
    date = new Date(date);
    const hDate = new HDate(date);
    const hMonth = hDate.getMonthName();
    const hYear = hDate.getFullYear();
    return getMonthRangeFromHebrew({ month: { value: hMonth }, year: { value: hYear } })
}

export const defaultDates = {
    cSharp: '0001-01-01T00:00:00',
    sqlServer: '1900-01-01T00:00:00',
};

export const daysOfWeek = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday'
}

global.dateTime = dateTime;

/**
 * @param {Date} date           Javascript Date object
 * @returns {string}            Formatted string having the full date and time 'yyyy-MM-ddThh:mm:ss'
 */
function getDateTime(date) {
    if (date == undefined || date == null || date.length < 3)
        return '';
    //date = new Date(date.replace(/-/g, '\/').replace(/T.+/, ''));
    date = new Date(date); // in case it's a string
    var day = date.getDate();
    var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();

    return pad(year, 4) + "-" + pad(month, 2) + "-" + pad(day, 2) + "T" + pad(hour, 2) + ':' + pad(minute, 2) + ':' + pad(second, 2);
}

/**
 * Converts a date string in 'MM/DD/YYYY hh:mm:ss AM/PM' format to 'yyyy-MM-dd'.
 * 
 * @param {string} dateString - Date string in the format 'MM/DD/YYYY hh:mm:ss AM/PM'.
 * @returns {string} - Formatted string in the 'yyyy-MM-dd' format.
 */
// IT USES UTC
function convertStringsMMDDYYYYToYYYYMMDD(dateString) {
    const date = new Date(dateString); // Parse the input date string
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

/**
 * @param {string} date         Takes any type of date string or object
 * @returns {Date}              Javascript Date Object
 */
function getDateObject(date) {
    if (isDateObject(date)) {
        return date;
    }

    if (date == undefined || date == null || date.length < 3)
        return '';
    date = new Date(date.replace(/-/g, '\/').replace(/T.+/, ''));
    return date;
}

function isDateObject(date) {
    return Object.prototype.toString.call(date) === '[object Date]';
}

/**
 * @param {string} dateTime           Dot Net DateTime object 'yyyy-MM-ddThh:mm:ss'
 * @returns {string}                  Formatted string having the full date 'yyyy-MM-dd'
 */
function getYearDateFromDateTime(d) {
    if (d == undefined || d == null || d.length < 3)
        return '';
    var date = new Date(d.replace(/-/g, '\/').replace(/T.+/, ''));
    var newDate = getUSAFormattedDateString(date);//.substring(0, 10);
    return newDate;
}
/**
 * @param {Date} date           Javascript Date object
 * @returns {string}            Formatted string having the full date 'yyyy-MM-dd'
 */
function getYearDate(d) {
    var newDate = getDateTime(d).substring(0, 10);
    return newDate;
}
/**
 * @param {Array} daysOfWeek                             a comma separated string or integer array of day numbers where Sunday = 0 and Saturday = 6
 * @param {boolean} includeAndStringBetweenLastDays      whether to include "and" between the last days in the formatted string
 * @returns {string}                                     Formatted string having the 3 chars day names of the included days, format: 'Sun, Thu, Fri', 
 * if all the days are consecutive then it will only return the first and last day, format: 'Mon - Fri'
 */
function getWeekdaysString(daysOfWeek, includeAndStringBetweenLastDays = false) {
    if (!daysOfWeek)
        return "";
    var daysArr = Array.isArray(daysOfWeek) ?
        daysOfWeek.map((d) => (parseInt(d))) :
        daysOfWeek.split(",").map((d) => parseInt(d.trim())).filter((d) => (Number.isInteger(d)));
    if (daysArr.length < 1)
        return "";
    var min = Math.min(...daysArr);
    var max = Math.max(...daysArr);
    var minDay = getWeekdayFromNum(min);
    var maxDay = getWeekdayFromNum(max);
    var weekdaysString = "";
    if (min === max) {
        weekdaysString = minDay.charAt(0).toUpperCase() + minDay.slice(1);
    }
    else if (numbers.isConsecutive(daysArr)) {
        weekdaysString = minDay.charAt(0).toUpperCase() + minDay.slice(1) + " - " + maxDay.charAt(0).toUpperCase() + maxDay.slice(1);
    } else {
        const formattedWeekDays = daysArr.sort(function (a, b) { return a - b }).map((day) => {
            day = getWeekdayFromNum(day);
            return day.charAt(0).toUpperCase() + day.slice(1)
        });

        weekdaysString = includeAndStringBetweenLastDays ?
            arrays.joinWithOxfordComma(formattedWeekDays) : formattedWeekDays.join(', ');
    }
    return weekdaysString;
}

/**
 * @param {Array} daysOfMonth                            a comma separated string or integer array of day numbers
 * @param {boolean} includeAndStringBetweenLastDays      whether to include "and" between the last days in the formatted string
 * @returns {string}                                     Formatted string having the ordinal format of the included days
 */
function getMonthDaysString(daysOfMonth, includeAndStringBetweenLastDays = false) {
    daysOfMonth = !daysOfMonth ? [] : Array.isArray(daysOfMonth) ? daysOfMonth : daysOfMonth
        .split(",").map((d) => parseInt(d.trim())).filter((d) => (Number.isInteger(d)));

    const ordinalDaysOfMonth = daysOfMonth.sort((a, b) => a - b).map(day => numbers.getOrdinalString(day));

    return includeAndStringBetweenLastDays ?
        arrays.joinWithOxfordComma(ordinalDaysOfMonth) : ordinalDaysOfMonth.join(', ');
}


function formatTimeAMPM(date) {
    date = new Date(Date.parse(date));
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function formatTime24(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes;
    return strTime;
}

/**
 * @param {Date | string | undefined} date           Javascript Date object
 * @returns {string}            Formatted date string 'MM/dd/yyyy'
 */
function getUSAFormattedDateString(date) {
    if (date == undefined || date == null || date.length < 3)
        return '';

    if (typeof date === 'string' || date instanceof String)
        date = new Date(date.replace(/-/g, '\/').replace(/T.+/, ''));

    var day = date.getDate();
    var month = date.getMonth() + 1;    // yields month (add one as '.getMonth()' is zero indexed)
    var year = date.getFullYear();

    var dateString = `${pad(month, 2)}/${pad(day, 2)}/${pad(year, 4)}`;
    return dateString;
}
function getFormattedDateTime(date) {
    if (!date)
        return '';
    if (!isValidDate(date))
        return ''
    const dateObj = new Date(date);
    return dateObj.toLocaleString('en-US');
}

/**
 * @returns {string}            Formatted dot-net date-time string yyyy-MM-ddThh:mm:ss
 */
function getCurrentFormattedDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based.
    const day = now.getDate().toString().padStart(2, '0');
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

function getDisplayValue(date) {
    if (!date)
        return '';
    if (!isValidDate(date))
        return ''
    const dateObj = new Date(date);
    const timeStr = getTimeString(date);

    if (isToday(dateObj)) {
        return timeStr;
    }

    const dateStr = dateObj.toLocaleDateString('en-US');

    if (isYesterday(dateObj)) {
        return `Yesterday ${timeStr}`;
    }

    if (isThisWeek(dateObj)) {
        return `${dateObj.toLocaleDateString('en-US', { weekday: 'long' })} ${timeStr}`;
    }

    return `${dateStr} ${timeStr}`;
}

function getShortMonthAndDay(date) {
    if (!date)
        return '';
    if (!isValidDate(date))
        return ''
    const dateObj = new Date(date);

    const month = dateObj.toLocaleString('en-US', { month: 'short' });
    const day = dateObj.getDate();

    return `${month}/${day}`;

}

function parseTime(t) {
    var hh = t.getHours();
    var mm = t.getMinutes();
    var ampm = "AM";
    return pad(hh, 2) + ":" + pad(mm, 2);// + " " + ampm;
}

/**
 * 
 * @param {string} dateString           date string form of 'yyyy-MM-ddThh:mm:ss'
 * @return {string}                     time string in form of 'hh:mm:ss'
 */
function getTimeFromDateString(dateString) {
    const dateChunks = dateString.split('T');
    return dateChunks[1];
}

function getDay(date, length = 3) {
    date = new Date(date);
    var day = date.getDay();
    // global.log(day);
    return daysOfWeek[day].toString().substring(0, length);
}

function getWeekdayFromNum(day, length = 3) {
    if (!Number.isInteger(day) || day > 6) {
        return '';
    }
    return daysOfWeek[day].toString().substring(0, length);
}

function parseTimeToDate(t) {
    var d = new Date();
    var time = t.toString().match(/(\d+)(?::(\d\d))?\s*(p?)/);
    d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
    d.setMinutes(parseInt(time[2]) || 0);
    return d;
}

function pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}

function applyDate(date, dateToApply) {
    var d = new Date(date);
    var dToApply = new Date(dateToApply);
    d.setDate(dToApply.getDate());
    d.setFullYear(dToApply.getFullYear());
    return getYearDate(d)
}
function applyTime(date, timeToApply) {
    var d = new Date(date);
    d.setFullYear(d.getFullYear());
    d.setDate(d.getDate());
    d.setHours(timeToApply.toString().split(":")[0]);
    d.setMinutes(timeToApply.toString().split(":")[1]);
    return getDateTime(d);
}

function getAge(contact) {
    if (global.isEmpty(contact)) {
        return "-";
    }
    if (contact.dob != null) {
        var birth_date = global._DateFieldDB(contact.dob);
        if (!global.isEmpty(birth_date)) {
            var age = global._calculateAge(new Date(birth_date));
            if (Number.isNaN(age) || age == "NaN")
                return ""
            return age;
        }
        return contact.age;
    }
    if (global.isEmpty(contact.age)) {
        return "";
    }
    if (Number.isNaN(contact.age) || contact.age == "NaN") return ""
    if (contact.age == "0") return ""
    return contact.age;
}

/**
 * 
 * @param {String} dateValue 
 */
function isDateDefaultValue(dateValue) {
    return dateValue === defaultDates.cSharp || dateValue === defaultDates.sqlServer;
}

/**
 * 
 * @param {String} dateValue 
 */
function filterDefaultDate(dateValue, replacement = null) {
    return isDateDefaultValue(dateValue) ? replacement : dateValue;
}
function replaceEmptyDatesWithDefault(date, language = "cSharp") {
    return !date ? defaultDates[language] : date;
}

function formatStartAndEndTime(startTime, endTime) {
    const separator = endTime ? " - " : "";
    const formattedStartTime = startTime ? formatTimeAMPM(startTime) : "";
    const formattedEndTime = endTime ? formatTimeAMPM(endTime) : "";

    //only shows time when starTime is truthy, since showing the end time alone won't make sense without it
    return startTime ? `${formattedStartTime}${separator}${formattedEndTime}` : "";
}

function formatStartAndEndDate(startDate, endDate) {
    const formattedStartDate = startDate ? getUSAFormattedDateString(startDate) : "?";
    const formattedEndTime = endDate ? getUSAFormattedDateString(endDate) : "?";
    return `${formattedStartDate} - ${formattedEndTime}`;
};

/**
 * 
 * @param {Date?} a_start 
 * @param {Date?} a_end 
 * @param {Date?} b_start 
 * @param {Date?} b_end 
 * @returns 
 */
function dateTimeRangeOverlaps(a_start, a_end, b_start, b_end) {
    if ((a_start == null && a_end == null) || (b_start == null && b_end == null)) return true;
    if ((a_start == null && b_start == null) || (a_end == null && b_end == null)) return true;
    //ranges without defined start dates/times
    if (a_start == null && b_start <= a_end) return true;
    if (b_start == null && a_start <= b_end) return true;
    //ranges without defined end dates/times
    if (a_end == null && a_start <= b_start) return true; //b starts in a
    if (a_end == null && b_end < a_start) return false; //b starts in a
    if (b_end == null && b_start <= a_start) return true; //a start in b
    //ranges with defined end dates/times AND start date/times
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start < a_start && a_end < b_end) return true; // a in b
    return false;
}

/**
 * 
 * @param {Date} date
 * @return {Date}
 */
function getBeginningOfWeek(date) {
    const beginningOfWeek = new Date(date);
    beginningOfWeek.setDate(date.getDate() - date.getDay());
    return beginningOfWeek;
}

/**
 * 
 * @param {Date} date 
 * @return {Date}
 */
function getBeginningOfMonth(date) {
    const beginningOfMonth = new Date(date);
    beginningOfMonth.setDate(1);
    return beginningOfMonth;
}

/**
 * 
 * @param {Date} dateA 
 * @param {Date} dateB 
 */
function getNumberOfDaysBetweenDates(dateA, dateB) {
    return Math.floor(Math.abs(dateA.getTime() - dateB.getTime()) / (1000 * 3600 * 24));
}

/**
 * 
 * @param {date} date 
 */
function isCurrentWeek(date) {
    const WEEK_LENGTH = 604800000;
    let lastMonday = new Date(); // Creating new date object for today
    lastMonday.setDate(lastMonday.getDate() - (lastMonday.getDay() - 1)); // Setting date to last monday
    lastMonday.setHours(0, 0, 0, 0); // Setting Hour to 00:00:00:00
    const res = lastMonday.getTime() <= date.getTime() &&
        date.getTime() < (lastMonday.getTime() + WEEK_LENGTH);
    return res; // true / false
}


function isYesterday(date) {
    const yesterday = new Date(Date.now() - 86400000);
    return date.toDateString() === yesterday.toDateString();
}

function isThisWeek(date) {
    const today = new Date();
    const firstDayOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    return date >= firstDayOfWeek;
}

/**
 * 
 * @param {date} date 
 */
function isToday(date) {
    let todaysDate = new Date();
    // call setHours to take the time out of the comparison
    return date.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0)
}
/**
 * 
 * @param {Date} dateA 
 * @param {Date} dateB
 */
function getNumberOfWeeksBetweenDates(dateA, dateB) {
    return Math.floor(Math.abs(dateA.getTime() - dateB.getTime()) / (1000 * 3600 * 24 * 7));
}

/**
 * 
 * @param {Date} dateA 
 * @param {Date} dateB
 */
function getNumberOfMonthsBetweenDates(dateA, dateB) {
    let months = (dateB.getFullYear() - dateA.getFullYear()) * 12;
    months -= dateB.getMonth();
    months += dateA.getMonth();

    return Math.abs(months);
}

/**
 * 
 * @param {number} startDay 
 * @param {number} targetDay 
 */
function getDaysUntilNextWeekday(startDay, targetDay) {
    if (startDay < targetDay) {
        return targetDay - startDay;
    } else {
        return (targetDay + 7) - startDay;
    }
}

/**
 * 
 * @param {Date} startDate 
 * @param {int} targetMonthDay 
 */
function getDaysUntilNextMonthDay(startDate, targetMonthDay) {
    if (startDate.getDate() < targetMonthDay) {
        return targetMonthDay - startDate.getDate();
    } else {
        let nextMonthDate = new Date(startDate)
        nextMonthDate.setMonth(startDate.getMonth() + 1);
        nextMonthDate.setDate(targetMonthDay);

        return getNumberOfDaysBetweenDates(startDate, nextMonthDate);
    }
}

/**
 * @typedef {{start: Date, end: Date}} Interval 
 * @param {Array<Interval>} intervals 
 * @returns {Array<Interval>}
 */

//for an explanation, please see https://web.archive.org/web/*/https://www.geeksforgeeks.org/merging-intervals/
function combineIntervals(intervals = []) {
    if (!intervals || intervals.length == 0) {
        return intervals;
    }

    intervals.sort((a, b) =>
        a.start.getTime() - b.start.getTime()
    );

    const combinedIntervals = [];
    combinedIntervals.push(intervals[0]);
    for (let j = 1; j < intervals.length; j++) {
        const lastCombinedInterval = combinedIntervals[combinedIntervals.length - 1];
        const currentInterval = intervals[j];

        const intervalsOverlap = dateTimeRangeOverlaps(
            lastCombinedInterval.start,
            lastCombinedInterval.end,
            currentInterval.start,
            currentInterval.end
        );

        if (!intervalsOverlap) {
            combinedIntervals.push(currentInterval);
        } else if (currentInterval.end > lastCombinedInterval.end) {
            combinedIntervals[combinedIntervals.length - 1].end = currentInterval.end;
        }
    }

    return combinedIntervals;
}

/**
 * @typedef {{start: Date, end: Date}} Interval 
 * @param {Array<Interval>} openWindows 
 * @param {Array<Interval>} windowsToClose 
 * @returns {Array<Interval>}
 */
//take two array one of open windows and one of windows to close, and return an array with closed window
//Note: Only working on time windows of the same day
function closeWindows(openWindows = [], windowsToClose = []) {
    if (!windowsToClose || windowsToClose.length === 0 || !openWindows || openWindows.length === 0) {
        return openWindows;
    }

    openWindows.sort((a, b) =>
        a.start.getTime() - b.start.getTime()
    );
    windowsToClose.sort((a, b) =>
        a.start.getTime() - b.start.getTime()
    );

    //start with existing open windows
    let openWindowSchedules = openWindows;


    //close windows one by one and update array
    windowsToClose.forEach((winToClose) => {

        let tempOpenWindows = [];//will be used to override the openWindowSchedules array after every closing window

        //find which of the open windows needs closing
        openWindows.forEach(openWin => {
            //if it doesn't overlap push it as is
            if (!dateTimeRangeOverlaps(openWin.start, openWin.end, winToClose.start, winToClose.end))
                tempOpenWindows.push(openWin);
            else {
                //find opening at beginning, if any
                if (openWin.start < winToClose.start)
                    tempOpenWindows.push({ start: openWin.start, end: winToClose.start })
                //find opening at the end if any
                if (openWin.end > winToClose.end)
                    tempOpenWindows.push({ start: winToClose.end, end: openWin.end })
                //Note: Current structure is wastefull Becasue the openWindows & windowsToClose are already combined,
                //so there's no worries that you will push openings that will
                //be closed by the next windowToClose,
                //which means there's no need to rewrite the array so many times,
                //so it could technically be improved
            }
        })
        openWindowSchedules = tempOpenWindows;//override array and start process again for next window
    })

    return openWindowSchedules;
}

function isValidHhMm(inputField) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField.value);

    return isValid;
}

Date.prototype.isValid = function () {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    return this.getTime() === this.getTime();
};
function isValidDate(date) {
    var isValid = false;

    var d = new Date(date);

    isValid = d.isValid();

    return isValid;
}

function isValidTime(timeValue) {
    var isValid = false;

    var d = new Date(),
        s = timeValue,
        parts = timeValue.match(/(\d+)\.(\d+) (\w+)/),
        hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10) + 12,
        minutes = parseInt(parts[2], 10);

    d.setHours(hours);
    d.setMinutes(minutes);

    if (Object.prototype.toString.call(d) === "[object Date]") {
        // it is a date
        if (isNaN(d.getTime())) {  // d.valueOf() could also work
            // date is not valid
        } else {
            isValid = true;
        }
    } else {
        // not a date
    }

    return isValid;
}

function isDatePast(date) {
    if (!date || !isValidDate(date))
        return;
    if (new Date(date).setHours(0, 0, 0, 0) < (new Date().setHours(0, 0, 0, 0))) {
        return true;
    }

    return false;
};

function addDays(date, days) {
    if (!date || !isValidDate(date))
        return;
    let dateObj = new Date(date);
    dateObj.setDate(dateObj.getDate() + days)
    return dateObj;
}

function getDateOnly(date) {
    if (!date) return;
    date = new Date(date)
    return new Date(date.setHours(0, 0, 0, 0))
}

function isGreaterThanToday(date) {
    if (!date) return;
    let currentDate = new Date();
    let givenDate = new Date(date);
    return givenDate > currentDate
}
function isDateOnlyEqual(date1, date2) {
    if (!date1 || !date2) return;
    date1 = getUSAFormattedDateString(date1);
    date2 = getUSAFormattedDateString(date2);
    return date1 === date2;

}
function getTimeString(date, hour12 = true) {
    const dateObj = new Date(date);

    const timeStr = dateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: hour12 });

    return timeStr;
}

function getTimeStringNew(date, convertToLocal) {
    const dateObj = new Date(date);
    const timeStr = convertToLocal ? dateObj.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    }) : dateTime.getTimeString(dateObj);

    return timeStr;
};

function getDateString(date) {
    const [year, month, day] = date.split('-').map(Number);
    const dateObj = new Date(year, month - 1, day);

    const stringified = dateObj.toLocaleDateString();

    return stringified;
}

function getDateWithDay(date) {
    const options = { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' };
    const newDate = new Date(date);

    const formattedDate = newDate.toLocaleDateString('en-US', options);
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const year = newDate.getFullYear();

    return `${formattedDate} `;
}

function getDate(date) {
    const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    const newDate = new Date(date);

    const formattedDate = newDate.toLocaleDateString('en-US', options);
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const day = newDate.getDate().toString().padStart(2, '0');
    const year = newDate.getFullYear();

    return `${formattedDate} `;
}

function getShortDisplayDate(inputDate) {
    inputDate = new Date(inputDate);
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    // Directly setting UTC day to avoid local time zone discrepancies
    const day = inputDate.getUTCDate();
    const month = inputDate.getUTCMonth() + 1;
    const year = inputDate.getUTCFullYear();
    const weekday = weekdays[inputDate.getUTCDay()];

    const formattedDay = (day < 10) ? '0' + day : day;
    const formattedMonth = (month < 10) ? '0' + month : month;

    return `${weekday} ${formattedMonth}/${formattedDay}/${year % 100}`;
}

function getDayAndDate(dtStr) {
    const [year, month, day] = dtStr.split('-');
    const date = new Date(Number(year), Number(month) - 1, Number(day));
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = daysOfWeek[date.getDay()];

    return `${dayOfWeek} ${month}/${day}`;
}

function getTotalHours(start, end) {
    // Parse start and end times into Date objects
    const startDate = new Date(start);
    const endDate = new Date(end);

    // Calculate the time difference in milliseconds
    const timeDiff = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to hours
    const totalHours = timeDiff / (1000 * 60 * 60);

    return Math.abs(totalHours); // Use Math.abs() to ensure positive result
}

//function getShortTimeFormat(date: Date): string {
export const getShortTimeFormat = (date, withM = true) => {
    if (!date) return;
    const am = withM ? 'am' : 'a';
    const pm = withM ? 'pm' : 'p';

    date = new Date(date);
    const hour = date.getHours() % 12; // Get hour in 12-hour format (1-12)
    const fHour = hour == 0 ? 12 : hour;
    const minute = date.getMinutes().toString().padStart(2, '0');
    const period = date.getHours() < 12 ? am : pm;

    return minute === '00' ? `${fHour}${period}` : `${fHour}:${minute}${period}`;
};
//export const getLastDayOfMonth = (month: number, year: number): number => {
export const getLastDayOfMonth = (month, year) => {
    // Create a Date object for the first day of the next month
    const nextMonth = month === 12 ? 1 : month + 1;
    const nextYear = month === 12 ? year + 1 : year;
    const nextMonthDate = new Date(nextYear, nextMonth, 0); // Set day to 0 (last day of previous month)

    return nextMonthDate;
}
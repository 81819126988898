import React, { useEffect, useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Paper, IconButton, Button } from '@mui/material';
import ReactLoader from '../../../../../components/common/ReactLoader';
import useFetcher from '../../../../../hooks/useFetcher';
import { dateTime } from '../../../../../helper/datetime';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Calendar from '../../../../../pages/Calendar/Calendar';
import { app_xxx_light_bg } from '../../../../../data/constants';
import { ClockIcon } from '@mui/x-date-pickers-pro';

type PatientWeekProps = {
    patientId: number;
    weekDates: [Date, Date];
    showPatientSchedule?: any;
};

type CaregiverLightDto = {
    caregiverId: number;
    hxCaregiverId: number | null;
    caregiverCode: string | null;
    name: string;
};

type PatientScheduleDto = {
    scheduleId: number;
    scheduleCaregiver: CaregiverLightDto;
    scheduleEndTime?: Date;
    scheduleStartTime?: Date;
    clockInTime?: Date;
    clockOutTime?: Date;
    isMissedVisit: boolean;
};

type PatientMasterDto = {
    masterCaregiver: CaregiverLightDto;
    masterStartTime?: Date;
    masterEndTime?: Date;
};

type PatientDayDto = {
    dayOfWeek: string;
    dayDate: Date;
    visits: PatientScheduleDto[];
    masters: PatientMasterDto[];
};

const PatientWeek: React.FC<PatientWeekProps> = ({ patientId, weekDates, showPatientSchedule, }) => {
    const [weekData, setWeekData] = useState<PatientDayDto[]>([]);
    const [showFull, setShowFull] = useState(false);
    const fetcher = useFetcher();

    const formatTime = (date: string) => {
        const time = new Date(date);
        return (time instanceof Date && !isNaN(time.getTime())) ? time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : 'N/A';
    };

    const getWeekData = (sentDate: Date) => {
        const date = sentDate instanceof Date ? sentDate : new Date(sentDate);

        const date1 = sentDate instanceof Date ? sentDate : new Date(dateTime.getDateObject(sentDate));

        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

        fetcher.get(`schedule/patient/by_week/${patientId}/${formattedDate}`).then((response) => {
            setWeekData(response.data.data);
        })
    }

    useEffect(() => {
        if (fetcher.isLoading)
            return;

        getWeekData(weekDates[0])
    }, [patientId, weekDates]);

    const handleClickNext = () => {
        const date = new Date(weekData[6]?.dayDate)
        // if (date.getDate() < weekDates[1].getDate()) {
        date.setDate(date.getDate() + 1)
        getWeekData(date)
        // }
    }

    const handleClickBack = () => {
        const date = new Date(weekData[0]?.dayDate)
        // if (date.getDate() > weekDates[0].getDate()) {
        date.setDate(date.getDate() - 1)
        getWeekData(date)
        // }
    }

    const visitDatesIsBefore = () => {
        const date = new Date(weekData[0]?.dayDate)
        return date.getTime() > weekDates[0].getTime()
    }

    const visitDatesIsAfter = () => {
        const date = new Date(weekData[6]?.dayDate)
        return date.getTime() < weekDates[1].getTime()
    }

    return (
        <Paper style={{ maxWidth: 'none !important' }}>
            {showFull &&
                <Box>
                    <Calendar passedDate={weekDates[0]} module='patient' id={patientId.toString()} showCaregiver showPatient={false}
                        selectMode={true} />
                </Box>
            }
            {!showFull && <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <IconButton
                    color='primary' onClick={() => handleClickBack()}
                    sx={{
                        borderRadius: '0px',
                        backgroundColor: app_xxx_light_bg,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        marginLeft: 0,
                        width: 30,
                    }}>
                    <ArrowBackIos sx={{
                        margin: 0,
                        verticalAlign: 'middle',
                    }} />
                </IconButton>
                <Box sx={{ padding: '5px', minWidth: '1200px', minHeight: '170px' }}>
                    <ReactLoader isLoading={fetcher.isLoading} />
                    <Grid container spacing={0.5} direction="row">
                        {weekData.map((day, i) => (
                            <Grid item xs key={i}>
                                <Card sx={{ backgroundColor: 'grey.100', marginBottom: .25, padding: 0 }}>
                                    <CardContent
                                        sx={{
                                            padding: '2px 2px 2px 3px',
                                            '&:last-child': { paddingBottom: '2px' }
                                        }}>
                                        <Typography color="primary" variant="caption" sx={{ display: 'block' }}>
                                            <div>
                                                {day.dayOfWeek}{' '}{dateTime.getShortMonthAndDay(day.dayDate)}
                                            </div>
                                        </Typography>
                                    </CardContent>
                                </Card>
                                {day.masters.map((master, j) => (
                                    <Card key={j} sx={{ backgroundColor: 'grey.100', marginBottom: 1, padding: 0 }}>
                                        <CardContent
                                            sx={{
                                                padding: '2px 2px 2px 3px',
                                                '&:last-child': { paddingBottom: '2px' }
                                            }}>
                                            <Typography variant="caption" sx={{ display: 'block' }}>
                                                <strong>Master Caregiver:</strong>
                                                <Typography fontSize="12px">{!!master.masterCaregiver ? master.masterCaregiver.name : 'N/A'}</Typography>
                                            </Typography>
                                            <Typography variant="caption">{!!master.masterStartTime ? formatTime(new Date(master.masterStartTime).toString()) : 'N/A'}</Typography>
                                            <Typography variant='caption'> - </Typography>
                                            <Typography variant="caption">{!!master.masterEndTime ? formatTime(new Date(master.masterEndTime).toString()) : 'N/A'}</Typography>
                                        </CardContent>
                                    </Card>
                                ))}
                                {day.visits.map((visit, j) => (
                                    <Card key={j} sx={{ marginBottom: 1, padding: 0 }}>
                                        <CardContent
                                            sx={{
                                                padding: '2px 2px 2px 3px',
                                                '&:last-child': { paddingBottom: '2px' }
                                            }}>
                                            <Typography variant="caption" sx={{ display: 'block' }}>
                                                <strong>Visit Caregiver:</strong>
                                                <Typography fontSize="12px">{!!visit.scheduleCaregiver ? visit.scheduleCaregiver.name : 'N/A'}</Typography>
                                            </Typography>
                                            <Typography variant="caption">{visit.scheduleStartTime ? formatTime(visit.scheduleStartTime.toString()) : 'N/A'}</Typography>
                                            <Typography variant='caption'> - </Typography>
                                            <Typography variant="caption">{visit.scheduleEndTime ? formatTime(visit.scheduleEndTime.toString()) : 'N/A'}</Typography>
                                            <Typography variant="caption" sx={{ display: 'block' }}>
                                                <ClockIcon sx={{ fontSize: '13px' }} />
                                                {!!visit.clockInTime ? formatTime(new Date(visit.clockInTime).toString()) : 'N/A'}
                                                {" - "}
                                                {!!visit.clockOutTime ? formatTime(new Date(visit.clockOutTime).toString()) : 'N/A'}
                                            </Typography>

                                            {visit.isMissedVisit ? (
                                                <Typography variant="caption" sx={{ display: 'block' }} style={{ color: 'red' }}>
                                                    Missed
                                                </Typography>
                                            ) : <br></br>}
                                        </CardContent>
                                    </Card>
                                ))}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <IconButton
                    color='primary' onClick={() => handleClickNext()}
                    sx={{
                        borderRadius: '0px',
                        backgroundColor: app_xxx_light_bg,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: 0,
                        marginRight: 0,
                        width: 30,
                    }}>
                    <ArrowForwardIos sx={{
                        margin: 0,
                        verticalAlign: 'middle',
                    }} />
                </IconButton>
            </div>}
        </Paper>
    );
};

export default PatientWeek;